import React from 'react'
import kiosk from '../shared/square-kiosk.png'

const features1 = [
  {
    name: 'Address Staffing Complexities.',
    description: 'Never worry about training, sick days, vacation, and no shows. Use a kiosk for front-of-house operations.'
  },
  {
    name: 'Easy Setup.',
    description: 'We deliver a kiosk preloaded with your menu, which works immediately with your current kitchen printer/KDS.'
  },
  {
    name: 'Speed Up Service.',
    description: 'Customers can skip long lines and order on a kiosk. Shorter wait times = increased customer satisfaction.'
  },
]

const features = [
  {
    name: 'Square Loyalty',
    description:
      'Enroll, gain points, and redeem rewards directly on the kiosk'
  },
  {
    name: 'Text Message Notifications',
    description:
      'Your customers can receive a text message notifying them that their order is ready'
  },
  {
    name: 'Discount Codes',
    description:
      'Automatically apply scheduled discounts and redeem Square marketing coupons directly on the kiosk'
  },
  {
    name: 'Automatically syncs with Square',
    description:
      'Our kiosks sync with changes from the Square dashboard in real time'
  },
]

const PlatformSquare = () => {
  return (
    <div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Automation + Hospitality</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              A Kiosk built for Square
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Get up and running with our Square integrated kiosks in less than an hour.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mr-20 lg:mr-8 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-20 lg:max-w-none lg:grid-cols-2 lg:gap-y-25">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Go Big</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Large Screen Kiosks</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Our large screens attract customers compared to smaller ones that are easy to miss. We offer countertop, wall-mount, or stand kiosk options.
            </p>
            <br />
            <br />
          </div>
        </div>
        <div className="flex justify-center">
          <img
            src={kiosk}
            width={425}
            height={252}
          />
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features1.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="mx-auto mt-32 max-w-7xl sm:mt-28 sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
          <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Interested in a Kiosk?
          </h2>
          <br />
          <div className="text-center">
            <a
              href="/inquiry"
              target="_blank"
              className="text-xl rounded-md bg-indigo-600 px-3.5 py-2.5 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Schedule Demo
            </a>
          </div>
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient
                id="759c1415-0410-454c-8f7c-9a820de03641"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)"
              >
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  )
}
export default PlatformSquare
