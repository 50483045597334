import React, { useState, useMemo, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import {
  Button,
  Card,
  Col,
  Row,
  Container,
  Modal,
  Form,
} from 'react-bootstrap'

import OptionsTable from './OptionsTable'

import {
  BaseCard,
  EditIcon,
  styles,
  Client,
  formatPrice,
  SortableList,
} from '../shared'

import {
  useMenuContext,
  types,
} from '../state/MenuProvider'

const ItemEditModal = ({
  show,
  onHide,
  item,
}) => {

  const [state, dispatch] = useMenuContext()
  const [updatedItem, setUpdatedItem] = useState({
    id: item.item_id,
    name: item.item_name,
    description: item.item_description,
    price: item.item_price,
    imageURL: item.item_medium_photo_url, // if imageURL doesn't exist: it'll be set to a `File` object
    previewImgFile: null,
  })

  const [selectedOptions, setSelectedOptions] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const client = new Client
  const formDisabled = state.paymentProcessor === 'square'

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        Edit Item
      </Modal.Header>
      <Modal.Body>
        <Form>
          <BaseCard>
            <Row>
              <Col md={6}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column md='3'>Name</Form.Label>
                  <Col md='9'>
                    <Form.Control
                      value={updatedItem.name}
                      aria-label='name'
                      disabled={formDisabled}
                      onChange={
                        (e) => {
                          setUpdatedItem({
                            ...updatedItem,
                            name: e.target.value
                          })
                        }
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column md='3'>Description</Form.Label>
                  <Col md='9'>
                    <Form.Control
                      value={updatedItem.description}
                      aria-label='description'
                      disabled={formDisabled}
                      onChange={
                        (e) => {
                          setUpdatedItem({
                            ...updatedItem,
                            description: e.target.value
                          })
                        }
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column md='3'>Price in cents</Form.Label>
                  <Col md='9'>
                    <Form.Control
                      aria-label='price'
                      disabled={formDisabled}
                      value={updatedItem.price}
                      onChange={
                        (e) => {
                          const price = parseInt(e.target.value || 0)
                          setUpdatedItem({
                            ...updatedItem,
                            price
                          })
                        }
                      }
                    />
                  </Col>
                </Form.Group>

              </Col>
              <Col>
                <img
                  style={{width: 300, height: 'auto'}}
                  src={updatedItem.previewImgFile || updatedItem.imageURL}
                />

                <div className="row">
                  <div className="col-8">
                    <label className="btn btn-default p-0">
                      <input type="file" accept="image/*"
                        onChange={(event) => {
                          const file = event.target.files[0]
                          const previewImgFile = URL.createObjectURL(file)
                          setUpdatedItem({
                            ...updatedItem,
                            imageURL: file,
                            previewImgFile,
                          })
                        }

                      } />
                    </label>
                  </div>
                </div>

              </Col>
            </Row>
          </BaseCard>
          <OptionsTable
            item={item}
            setSelectedOptions={setSelectedOptions}
          />
        </Form>


      </Modal.Body>
      <Modal.Footer>
        <Button 
        style={{backgroundColor: '#ff6666', fontWeight: 'bold'}}
        onClick={onHide}>Cancel
        </Button>
        {
          submitting ? (
            <Button
            style={{backgroundColor: "#cce0ff", color: "black", fontWeight: 'bold'}}
            disabled>Processing...</Button>
          ) : (
            <Button 
            style={{backgroundColor: '#6666ff', fontWeight: 'bold'}}
            onClick={() => {
              setSubmitting(true)

              client.updateItem(updatedItem, selectedOptions)
                .then(() => {
                  if(updatedItem.imageURL instanceof File) {
                    client.uploadPhoto({
                      imageFile: updatedItem.imageURL,
                      targetType: 'item',
                      targetID: updatedItem.id,
                    })
                      .then(({
                        item_photo_url,
                        item_thumbnail_url,
                      }) => {
                        const nextUpdatedItem = {
                          ...updatedItem,
                          item_photo_url,
                          item_thumbnail_url,
                        }
                        dispatch({
                          type: types.UPDATE_ITEM,
                          payload: {
                            updatedItem: nextUpdatedItem,
                            selectedOptions: item.options
                          }
                        })

                        setSubmitting(false)
                        onHide()
                      })
                      .catch(() => setSubmitting(false))
                  } else {
                    dispatch({
                      type: types.UPDATE_ITEM,
                      payload: {
                        updatedItem,
                        selectedOptions: item.options
                      }
                    })

                    setSubmitting(false)
                    onHide()
                  }
                })
                .catch(() => setSubmitting(false))
            }}>
              Save
            </Button>
          )
        }
      </Modal.Footer>
    </Modal>
  )
}

const ItemEdit = ({
  item
}) => {
  const [show, setShow] = useState(false)

  return (
    <div >
      <div data-test-id='item-edit-icon'>
        <EditIcon
          onClick={()=> {
            setShow(!show)
          }}
        />
      </div>
      <ItemEditModal
        show={show}
        item={item}
        onHide={
          ()=> setShow(false)
        }
      />
    </div>
  )
}

// NOTE: if changing this table consider if the same changes
// need to be made to the OptionsTable
const Options = ({data}) => {
  const columns = [
    {
      name: 'Option',
      selector: row => row.option_name,
      sortable: true,
    },
    {
      id: 'optionMinMax',
      name: 'Min / Max Required',
      selector: row => `${row.option_min} / ${row.option_max}`,
      sortable: true,
    },
    {
      name: 'Choices',
      grow: 2,
      cell: row => {
        const choices = row.choices.map(choice => {
          return choice.choice_name
        }).join(', ')

        return <div>{ choices }</div>
      }
    },
  ]

  return (
    <Container>
      <BaseCard>
        <DataTable
          columns={columns}
          customStyles={styles.dataTableStyles}
          data={data.options}
        />
      </BaseCard>
    </Container>
  )

}

const Category = () => {
  const [expand, setExpand] = useState(false)
  const [reorder, setReorder] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [state, dispatch] = useMenuContext()
  const [selectedRows, setSelectedRows] = useState([])
  const [toggleClear, setToggleClear] = useState(false)

  const client = new Client
  const {
    currentMenu,
    currentCategory:{
      category_id: categoryID,
      category_name: categoryName,
      items,
    },
    paymentProcessor,
  } = state

	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows)
	}, [])

  const differenceBy = (array1, array2, key) => {
    const arr_copy = [...array1]
    return arr_copy.filter(a => !array2.some(b => b[key] === a[key]))
  }

  const columns = useMemo(
    () => [
      {
        id: 'item',
        name: 'Item',
        selector: row => row.item_name,
        sortable: true,
      },
      {
        id: 'thumbnail',
        name: 'Photo',
        cell: row => {
          return <img alt='item-image' src={row.item_thumbnail_url} />
        },
      },
      {
        id: 'price',
        name: 'Price',
        selector: row => formatPrice(row.item_price),
        sortable: true,
      },
      {
        id: 'action',
        name: 'Edit',
        cell: row => {
          return <ItemEdit item={row}/>
        }
      },
      {
        id: 'delete',
        omit: paymentProcessor === 'square',
        name: 'Delete',
        cell: row => {
          return (
            <Button
              style={{
                backgroundColor: "red",
                fontWeight: 'bold',
                color: 'white'
              }}
              onClick={() => {
                const itemID = row.item_id
                client.deleteItem(itemID)
                  .then(() => {
                    dispatch({
                      type: types.REMOVE_ITEM,
                      payload: itemID
                    })
                  })
              }}
            >
              Delete
            </Button>
          )
        }
      },
    ]
  )

  const style = {
    margin: 10,
    fontSize: 18,
    color: "#0876f3",
    backgroundColor: "#EBEBEB",
    border: "none"
  }

  return (
    <>
      <h3>{categoryName}</h3>

      {
        reorder ? (
          <SortableList
            collection={items}
            collectionItemID={'item_id'}
            displayAttribute={'item_name'}
            onCancel={()=> setReorder(false)}
            onDragEnd={(newOrderOfItems)=> {
              dispatch({
                type: types.UPDATE_ITEMS,
                payload: newOrderOfItems
              })
            }}
            onSave={()=> {
              setReorder(false)
              const itemOrderIDs = items.map((c => c.item_id))

              client.updateItemOrder(
                categoryID,
                itemOrderIDs
              )
            }}
          />
        ) : (
          <>
            <Row>
              {
                paymentProcessor === 'square' ? null : (
                  <Col>
                    <Card>
                      <Card.Body>
                        <Button
                          style={style}
                          aria-label={'add-item'}
                          onClick={()=> {
                            client.addItem(categoryID)
                              .then(newItem => {
                                dispatch({
                                  type: types.ADD_ITEM,
                                  payload: newItem,
                                })
                              })
                          }}
                        >
                          Add Item
                        </Button>

                        <Button
                          style={style}
                          variant="success"
                          onClick={() => {
                            client.copyItems(currentMenu.id, categoryID, selectedRows)
                              .then(()=> {
                                window.location.reload()
                              })
                          }}
                        >
                          Copy
                        </Button>
                        <Button
                          style={style}
                          variant="warning"
                          onClick={()=> setShowMoveModal(true)}
                        >
                          Move
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>

                )
              }
              <Col md={6}>
                <Card>
                  <Card.Body>
                    <Button
                      style={style}
                      variant="info"
                      onClick={() => setReorder(true)}
                    >
                      Reorder
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <Card.Body>
                    <Button
                      style={style}
                      onClick={()=> {
                        setExpand(!expand)
                      }}
                    >
                      {expand ? 'Collapse': 'Expand All'}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={items}
              selectableRows
              persistTableHead
              fixedHeader
              expandableRows
              expandOnRowClicked
              expandableRowExpanded={row => expand}
              onSelectedRowsChange={handleRowSelected}
              expandableRowsComponent={Options}
              keyField={'item_id'}
              customStyles={styles.dataTableStyles}
            />
          </>
        )
      }
    </>
  )
}

export default Category
