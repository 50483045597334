import React, { useReducer, createContext, useContext } from "react"
export const MenuContext = createContext()

const initialState = {
  currentCategory: {},
  currentMenu: {},
  categories: [],
  menus: [],
  allOptions: [],
  layout: null,
}

export const types = {
  LOAD_INIT: 'LOAD_INIT',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_ITEM_OPTION: 'REMOVE_ITEM_OPTION',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  UPDATE_ITEMS: 'UPDATE_ITEMS',
  UPDATE_ITEM_OPTION: 'UPDATE_ITEM_OPTION',
  ADD_ITEM: 'ADD_ITEM',
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_ITEM_OPTION: 'ADD_ITEM_OPTION',
  UPDATE_CATEGORY_NAMES: 'UPDATE_CATEGORY_NAMES',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  REMOVE_ITEM: 'REMOVE_ITEM',
  REMOVE_OPTION: 'REMOVE_OPTION',
  REMOVE_CHOICE: 'REMOVE_CHOICE',
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.REMOVE_ITEM: {
      const itemID = action.payload

      const nextItems = state
        .currentCategory
        .items.filter(item => item.item_id !== itemID)

      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          items: nextItems
        }
      }
    }
    case types.REMOVE_OPTION: {
      const {
        itemID,
        optionID,
      } = action.payload

      const {
        currentCategory
      } = state

      const nextItems = currentCategory
        .items.map(item => {
          if(item.item_id === itemID) {
            const nextOptions = item.options
              .filter(option => option.option_id !== optionID)

            item.options = nextOptions
          }

          return item
        })


      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          items: nextItems
        }
      }

    }
    case types.REMOVE_CATEGORY: {
      const categoryIDtoDelete = action.payload

      const {
        currentCategory,
        categories,
      } = state

      const nextCurrentCategory = categories[0].category_id === categoryIDtoDelete ?
        categories[1] :
        categories[0]

      const nextCategories = categories
        .filter(category => category.category_id !== categoryIDtoDelete)

      return {
        ...state,
        currentCategory: nextCurrentCategory,
        categories: nextCategories
      }
    }
    case types.UPDATE_CATEGORY_NAMES: {
      const mappedDiff = action.payload.reduce((memo, diff) => {
        const [id, name] = diff

        memo[id] = name

        return memo
      }, {})

      const nextCategories = state.categories.map(category => {
        const changedCatName = mappedDiff[category.category_id]

        if(changedCatName) {
          category.category_name = changedCatName
        }

        return category
      })

      return {
        ...state,
        categories: nextCategories
      }
    }
    case types.UPDATE_ITEM_OPTION: {
      const option = action.payload

      const updatedItem = state
        .currentCategory
        .items
        .filter(item => item.item_id === option.item_id)[0]

      let copiedItem = {...updatedItem}

      const updatedItems = state
        .currentCategory
        .items
        .map(item => {
          if(item.item_id === option.item_id) {
            const updatedOptions = item.options.map(mappedOption => {
              if(option.option_id === mappedOption.option_id) {
                return option
              } else {
                return mappedOption
              }
            })

            item.options = updatedOptions
          }

          return item
        })

      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          items: updatedItems
        }
      }
    }
    case types.UPDATE_ITEMS: {
      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          items: action.payload
        }
      }
    }
    case types.UPDATE_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case types.UPDATE_ITEM: {
      const {
        updatedItem,
        selectedOptions,
      } = action.payload

      const nextItems = state.currentCategory.items.map(item => {
        if (item.item_id === updatedItem.id) {
          if(updatedItem.item_thumbnail_url) {
            return {
              ...item,
              item_name: updatedItem.name,
              item_description: updatedItem.item_description,
              item_price: updatedItem.price,
              item_photo_url: updatedItem.item_photo_url,
              item_thumbnail_url: updatedItem.item_thumbnail_url,
              options: selectedOptions
            }
          } else {
            return {
              ...item,
              item_name: updatedItem.name,
              item_description: updatedItem.description,
              item_price: updatedItem.price,
              options: selectedOptions
            }

          }
        }

        return item
      })

      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          items: nextItems
        }
      }
    }
    case types.ADD_ITEM: {
      const item = action.payload

      const mappedItem = {
        item_id: item.id,
        item_name: item.name,
        item_price: item.price,
        options: []
      }

      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          items: [
            ...state.currentCategory.items,
            mappedItem
          ]
        }
      }
    }
    case types.ADD_CATEGORY: {
      return {
        ...state,
        categories: [...state.categories, action.payload]
      }
    }
    case types.REMOVE_ITEM_OPTION: {
      const {
        item,
        optionIndex,
        option,
      } = action.payload

      const {
        category_id,
        item_id,
      } = item

      const nextMenu = state.menu.map(categoryOG => {
        if(categoryOG.category_id === category_id) {
          const nextItems = categoryOG.items.map(itemOG => {
            if (itemOG.item_id === item_id) {
              itemOG.options.splice(optionIndex, 1)
            }

            return itemOG
          })
          return {
            ...categoryOG,
            items: nextItems
          }
        }

        return categoryOG

      })

      return {
        ...state,
        menu: nextMenu
      }
    }
    case types.ADD_ITEM_OPTION: {
      const {
        nextOptions,
        item,
      } = action.payload

      const updatedItems = state
        .currentCategory
        .items
        .map(mappedItem => {
          if (mappedItem.item_id == item.item_id) {
            const updatedItem = {
              ...item,
              options: nextOptions
            }

            return updatedItem
          } else {
            return mappedItem
          }
        })

      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          items: updatedItems
        }
      }
    }

    case types.LOAD_INIT:
      return {
        ...state,
        ...action.payload,
      }

    default:
      throw new Error()
  }
}
export const MenuProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <MenuContext.Provider value={[state, dispatch]}>
      {props.children}
    </MenuContext.Provider>
  )
}

export const useMenuContext = () => useContext(MenuContext)
