import React from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import {
  Button,
} from 'react-bootstrap'

import {
  BaseCard,
} from '../shared/'

import { SortableListItem } from '../components/SortableListItem'

const SortableList = ({
  collection,
  collectionItemID,
  displayAttribute,
  onCancel,
  onDragEnd,
  onSave,
}) => {
  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = collection.findIndex(c => String(c[collectionItemID]) === active.id)
      const newIndex = collection.findIndex(c => String(c[collectionItemID]) === over.id)
      const nextCollection = arrayMove(collection, oldIndex, newIndex)

      onDragEnd(nextCollection)
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  return (
    <BaseCard>
      <Button
        style={{ backgroundColor: '#6666ff', fontWeight: 'bold', marginRight:'10px' }}
        onClick={onSave}
      >
        Save
      </Button>
      <Button
        style={{ backgroundColor: '#ff6666', fontWeight: 'bold' }}
        onClick={onCancel}
      >
        Cancel Reorder
      </Button>
      <BaseCard>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={collection.map(c => String(c[collectionItemID]))}
            strategy={verticalListSortingStrategy}
          >
            {
              collection.map(collectionItem => {
                return (
                  <SortableListItem
                    key={collectionItem[collectionItemID]}
                    id={collectionItem[collectionItemID]}
                    name={collectionItem[displayAttribute]}
                  />
                )
              })
            }
          </SortableContext>
        </DndContext>
      </BaseCard>
    </BaseCard>
  )
}

export default SortableList
