import React from 'react'
import {
  Form
} from 'react-bootstrap'

const TextInput = ({
  field,
  label,
  placeholder,
  type = '',
  meta: {
    touched,
    errors,
    getFieldProps,
  }
}) => {
  return (
    <Form.Group>
      <Form.Label htmlFor={field}>{label}</Form.Label>
      <Form.Control
        id="firstName"
        placeholder={placeholder}
        type={type}
        isInvalid={touched[field] && errors[field]}
        isValid={touched[field] && !errors[field]}
        {...getFieldProps(field)}
      />
      <Form.Control.Feedback type="invalid">
        {errors[field]}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default TextInput
