import React, {useState} from "react";
import { ToggleSwitch, Client } from "../shared"

const DiscountCodes = ({
  locationID,
  discountCodesEnabled,
}) => {
  const client = new Client
  const [enabled, setEnabled] = useState(discountCodesEnabled)

  return (
    <>
      <ToggleSwitch
        message={enabled ? 'Enabled' : 'Disabled'}
        checked={enabled}
        onChange={() => {
          const nextEnabled = !enabled

          setEnabled(nextEnabled)

          const attributes = {
            meta: {
              discount_codes_enabled: nextEnabled
            }
          }

          client.updateLocation(locationID, attributes)
        }}
      />
    </>
  )
}

export default DiscountCodes;
