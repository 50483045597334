import React, { useState } from 'react'

import {
  Client,
  ToggleSwitch,
  DropDown,
} from '../shared'

import {
  Row,
  Col,
} from 'react-bootstrap'

const FulfillmentOptions = ({
  fulfillmentOptions,
  employees,
  locationID
}) => {
  const client = new Client

  const [options, setOptions] = useState(fulfillmentOptions)

  const handleChange = (optionType) => {
    const newOptions = {
      ...options,
      [optionType]: !options[optionType]
    }

    setOptions(newOptions)

    client.updateLocation(locationID, {
      meta: newOptions
    })
  }


  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div class="">
      <p className='text-xl'><strong>Dining Options</strong></p>
      <div>
        <p style={{ fontSize: 20 }}>Turn on dining options you would like to offer on the kiosk</p>
        <br />
        {
          Object.keys(fulfillmentOptions).map(optionName => {
            const options = fulfillmentOptions[optionName]
            return (
              <>
                {
                  options.map(option => {
                    const {
                      location_id,
                      available_options,
                      delivery_option,
                      payment_term,
                      name,
                      active,
                    } = option

                    const [currentPaymentTerm, setCurrentPaymentTerm] = useState(payment_term)
                    const deliveryOption = delivery_option === 'in_store' ? 'Dine In' : 'Take Out'
                    return (
                      <Row>
                        <Col md={employees ? {} : {span: 2}}>{deliveryOption}</Col>

                        {
                          employees ? (
                            <Col>
                              <DropDown
                                currentSelected={currentPaymentTerm}
                                selections={available_options.payment_terms}
                                onSelect={(payment_term)=> {
                                  setCurrentPaymentTerm(payment_term)

                                  client.updateFulFillmentOption(option.id, location_id, {payment_term})
                                }}
                              />
                            </Col>

                          ) : null
                        }
                        <Col>
                          <ToggleSwitch
                            checked={active}
                            onChange={(isActive)=> {
                              client.updateFulFillmentOption(option.id, location_id, {active: isActive})
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  })
                }
              </>
            )
          })
        }
      </div>
    </div>
  )

}

export default FulfillmentOptions
