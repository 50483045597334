import React, { useState, useEffect } from 'react'
import {
  Form,
  Button
} from 'react-bootstrap'

import {
  Client,
} from '../shared'

const ReceiptCustomization = ({
  receiptText,
  receiptDeliveryOptions,
  locationID,
}) => {
  const [text, setText] = useState(receiptText)
  const [deliveryOptions, setDeliveryOptions] = useState(receiptDeliveryOptions)
  const client = new Client

  const style = {
    card: { marginTop: 50 },
    cardBody: { fontSize: 20 },
    button: { fontSize: 20, fontWeight: 'bold' }
  }

  return (
    <div class="card border-light mb-3" style={style.card}>
      <p className='text-xl'><strong>Receipt Customization</strong></p>
      <div class="card-body" style={style.cardBody}>
        <Form>
          <Form.Group>
            <Form.Label>Add additional text to the bottom of your receipt</Form.Label>
            <Form.Control as="textarea"
              rows={3}
              value={text}
              maxLength={150}
              onChange={(event) => setText(event.target.value)} />
          </Form.Group>
        </Form>
        <br />
      </div>
      <div class="card-body" style={style.cardBody}>
        <Form>
          <Form.Group>
            <Form.Label>Receipt delivery options</Form.Label>
            <Form.Check
              type='checkbox'
              id='print'
              label='Print'
              checked={deliveryOptions.print}
              onChange={() => {
                setDeliveryOptions({
                  ...deliveryOptions,
                  print: !deliveryOptions.print
                })}
              }
            />
            <Form.Check
              type='checkbox'
              id='email'
              label='Email'
              checked={deliveryOptions.email}
              onChange={() => {
                setDeliveryOptions({
                  ...deliveryOptions,
                  email: !deliveryOptions.email
                })}
              }
            />
            <Form.Check
              type='checkbox'
              id='none'
              label='None'
              checked={deliveryOptions.none}
              onChange={() => {
                setDeliveryOptions({
                  ...deliveryOptions,
                  none: !deliveryOptions.none
                })}
              }
            />
          </Form.Group>
        </Form>
        <br />
        <button
          className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          style={{fontSize: 18, fontWeight: 'bold'}}
          onClick={() =>
              client.updateLocation(locationID, {
                meta: {
                  receipt: text,
                  receipt_delivery: deliveryOptions,
                }
              })
          }>
          Save
        </button>
      </div>
    </div>
  )
}

export default ReceiptCustomization
