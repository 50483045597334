import React, { useState } from 'react'
import squarekiosk from '../shared/square-kiosk.png'
import squarepos from '../shared/squarepos.png'
import hardware from '../shared/twokiosks.png'
import '../shared/style.css'
import {
  DocumentChartBarIcon,
  PlayIcon,
  ArrowsPointingInIcon,
  ClockIcon,
  CheckIcon,
  CurrencyDollarIcon,
  CloudArrowUpIcon
} from '@heroicons/react/20/solid'

import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "How do I get my Square integrated kiosk? ",
    answer:
      "Contact us by filling out our inquiry form and one of our team members will reach out to you within 24 hours. You can get a kiosk in 1-2 weeks!",
  },
  {
    question: "How do I set up the kiosk?",
    answer:
      "Setting up the kiosk hardware is very easy. Each kiosk comes with a manual. Self-installation typically take 15 to 30 minutes at most. We are also available to help install remotely.",
  },
  {
    question: "What kind of support do you provide?",
    answer:
      "Our regular customer service hours are 9 am to 6 pm PST. However, urgent issues will be addressed in a timely manner. We have staff members on pager duty 24/7.",
  },
  {
    question: "Are there any fees?",
    answer:
      "We charge a flat monthly subscription fee of $100 per kiosk. Unlike many of our competitors who charge 1.5-2.5% on top of what Square already charges you, we stick to a flat fee. We have different pricing options for the hardware (purchase or rental). See pricing page for more details.",
  },
  {
    question: "Is there a warranty on the kiosks? ",
    answer:
      "All of our kiosks that are purchased come with a 3 year warranty.",
  },
  
  // More questions...
]

const primaryFeatures = [
  {
    name: 'Auto-sync menu changes. ',
    description: 'Our kiosks sync with your Square catalog changes in real time without accessing a second dashboard.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Integrated reporting. ',
    description: 'Manage kiosk orders, payments and reporting on your Square account.',
    icon: DocumentChartBarIcon,
  },
  {
    name: 'Plug and play. ',
    description: 'Push kiosk orders automatically to your kitchen printer or KDS.',
    icon: PlayIcon,
  },
]
const secondaryFeatures = [
  {
    name: 'Increase Order Accuracy',
    description:
      'Ensure accuracy and minimizing the risk of mistakes.',
    icon: ArrowsPointingInIcon,
  },
  {
    name: 'Speed Up Service',
    description:
      'Reduce wait times and increase the number of customers your business can serve.',
    icon: ClockIcon,
  },
  {
    name: 'Reduce Labor Costs',
    description:
      'By automating the ordering process, you can reduce the need for front-of-house staff, lowering your labor costs',
    icon: CurrencyDollarIcon,
  },
]

const includedFeatures = [
  'Unlimited transactions',
  'No transactions fees',
  'Cancel anytime',
  'Square integration',
]

const LandingPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="relative isolate pt-10">
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>
          <div className="mx-auto max-w-7xl mb-20 px-6 py-20 sm:py-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
              <h1 className="max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Square + Kiosk
              </h1>
              <p className="text-2xl mt-6 leading-8 text-gray-600">
                Save thousands of dollars and increase customer satisfaction
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="/inquiry"
                  target="_blank"
                  className="text-xl rounded-md bg-indigo-600 px-3.5 py-2.5 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Schedule Demo
                </a>
              </div>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <title>Self service kiosk for Square</title>
              <img
                src={squarekiosk}
                className="mx-auto pt-10 w-[34rem] max-w-full"
                alt="Square integrated kiosk for restaurants and cafes"
              />
            </div>
          </div>
        </div>
        {/* Feature section */}
        <div className="mx-auto mt-30 max-w-7xl sm:mt-30 sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
              <div className="lg:row-start-2 lg:max-w-md">
                <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  Seamless Square Integration
                </h1>
              </div>
              <img
                src={squarepos}
                alt="Square Kiosk"
                className="relative -z-20 sm:min-w-full sm:max-w-xl shadow-xl lg:row-span-4 lg:max-w-none"
                width={600}
                height={350}
              />
              <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
                <dl className="max-w-xl space-y-8 text-lg leading-7 text-gray-300 lg:max-w-none">
                  {primaryFeatures.map((feature) => (
                    <div key={feature.name} className="relative">
                      <dt className="ml-9 inline-block font-semibold text-2xl text-white">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                        {feature.name}
                      </dt>
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div
              className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
              aria-hidden="true"
            >
              <div
                className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
        </div>

        {/* Feature section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Why Gamsa?
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {secondaryFeatures.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-lg flex items-center gap-x-3 font-semibold leading-7 text-gray-900">
                    <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-lg leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        {/* Hardware section*/}
        <div className="mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
              <div className="lg:row-start-2 lg:max-w-md">
                <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  Diverse Hardware Options
                </h1>
                <br />
                <h1 className="text-xl  tracking-tight text-white sm:text-2xl">
                  Samsung Kiosk   |   POSBANK Kiosk
                </h1>
              </div>
              <img
                src={hardware}
                alt="Samsung Kiosk and POSBANK Kiosk"
                className="relative -z-20 sm:min-w-full sm:max-w-xl shadow-xl lg:row-span-4 lg:max-w-none"
                width={500}
                height={500}
              />
              <div className="max-w-xl text-white lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
                <p className='text-lg'>Our large screen kiosks attract customers compared to smaller ones that are easy to miss. We offer countertop, wall-mount, or stand kiosk options.</p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="/pricing"
                    className="text-lg rounded-md bg-indigo-600 px-3.5 py-2.5 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Explore Hardware
                  </a>
                </div>
              </div>
            </div>
            <div
              className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
              aria-hidden="true"
            >
              <div
                className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
          </div>
        </div>
        {/* Pricing */}
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl sm:text-center">
              <h2 className="text-lg font-semibold leading-7 text-indigo-600">Pricing</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Subscription Plan
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">Monthly Subscription</h3>
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-lg font-semibold leading-6 text-indigo-600">What’s included</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul
                  role="list"
                  className="mt-8 grid grid-cols-1 gap-4 text-lg leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                >
                  {includedFeatures.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="mt-4 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">$100</span>
                      <span className="text-lg font-semibold leading-6 tracking-wide text-gray-600">USD /mo</span>
                    </p>
                    <br />
                    <p className="text-lg font-semibold text-gray-600">Per Kiosk</p>
                    <a
                      href="/pricing"
                      className="mt-8 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      See More Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ */}
        <div className="bg-white">
          <div className="mx-auto mt-32 max-w-7xl sm:mt-20 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-7xl divide-y divide-gray-900/10">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
              <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-3 pb-3">
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                            <span className="ml-6 flex h-7 items-center">
                              {open ? (
                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              ) : (
                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="mx-auto mt-32 max-w-7xl sm:mt-28 sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Interested in a Kiosk?
            </h2>
            <br />
            <div className="text-center">
              <a
                href="/inquiry"
                target="_blank"
                className="text-xl rounded-md bg-indigo-600 px-3.5 py-2.5 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Schedule Demo
              </a>
            </div>
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx={0}
                  cy={0}
                  r={1}
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>

      </main>
    </div>
  )
}

export default LandingPage
