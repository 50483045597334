const dataTableStyles = {
  rows: {
    style: {
      fontSize: 18
    }
  },
  headCells: {
    style: {
      fontSize: 18
    }
  },
  cells: {
    style: {
      fontSize: 18
    }
  }
}

const styles = {
  dataTableStyles
}

export { styles }
