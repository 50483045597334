import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'

import {
  Row,
  Col,
  Button,
  ToggleButton,
  Accordion,
  Container,
  Modal,
  Alert,
  Form,
} from 'react-bootstrap'

import {
  BaseCard,
  Client,
  SortableList,
  styles,
  DropDown,
  ClockIcon,
  GModal,
} from '../shared/'

import Category from './Category'
import {
  useMenuContext,
  types,
  MenuProvider,
} from '../state/MenuProvider'

const NavItem = ({
  item,
  selected,
  onClick,
  children,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'left',
      }}
    >
      <Row>
        <Col>
          <SelectButton
            text={item.name}
            selected={selected}
            onClick={() => onClick(item.id)}
          />
        </Col>
        <Col>
          {children}
        </Col>
      </Row>
    </div>
  )
}

const NavList = ({
  collection,
  onClick,
  title,
  selected,
  ItemComponent = NavItem,
}) => {
  return (
    <BaseCard>
      <div>
        {
          collection.map((item) => {
            const mappedItem = title === 'Menus' ? {
              name: item.menu_name,
              id: item.menu_id
            } : {
              name: item.category_name,
              id: item.category_id
            }
            return <ItemComponent
              item={mappedItem}
              key={mappedItem.id}
              selected={selected}
              onClick={onClick}
            />
          })
        }
      </div>
    </BaseCard>
  )
}

const SelectButton = ({
  text,
  selected,
  onClick,
}) => {
  return (

    <ToggleButton
      size={"lg"}
      className="mb-2"
      id={text}
      type="checkbox"
      variant="outline-primary"
      checked={selected === text}
      value="1"
      onChange={(e) => {
        onClick()
      }}
    >
      {text}
    </ToggleButton>

  )
}

const Loader = (props) => {
  const [state, dispatch] = useMenuContext()
  useEffect(() => {
    dispatch({
      type: types.LOAD_INIT,
      payload: props,
    })
  }, [])

  return (
    <Row>
      <Col md={3}>
        <div data-test-id='nav-bar' >
          <NavBar />
        </div>
      </Col>
      <Col md={9}>
        {
          state.currentCategory ? (
            <div data-test-id='category'>
              <Category />
            </div>
          )
            :
            null
        }
      </Col>
    </Row>
  )
}

const TimePicker = ({
  onSelect,
  selected,
}) => {
  const hours = {
    '-1':  'Off all day',
    0:  'On all day',
    360: '6:00 AM',
    390: '6:30 AM',
    420: '7:00 AM',
    450: '7:30 AM',
    480: '8:00 AM',
    510: '8:30 AM',
    540: '9:00 AM',
    570: '9:30 AM',
    600: '10:00 AM',
    630: '10:30 AM',
    660: '11:00 AM',
    690: '11:30 AM',
    720: '12:00 PM',
    750: '12:30 PM',
    780: '1:00 PM',
    810: '1:30 PM',
    840: '2:00 PM',
    870: '2:30 PM',
    900: '3:00 PM',
    930: '3:30 PM',
    960: '4:00 PM',
    990: '4:30 PM',
    1020: '5:00 PM',
    1050: '5:30 PM',
    1080: '6:00 PM',
    1110: '6:30 PM',
    1140: '7:00 PM',
    1170: '7:30 PM',
    1200: '8:00 PM',
    1230: '8:30 PM',
    1260: '9:00 PM',
    1290: '9:30 PM',
    1320: '10:00 PM',
    1350: '10:30 PM',
    1380: '11:00 PM',
    1410: '11:30 PM',
    1440: '12:00 AM',
  }
  return (
    <Container style={{width: 150}}>
      <DropDown
        selections={hours}
        currentSelected={selected}
        onSelect={onSelect}
      />
    </Container>
  )
}

const ScheduleCategoryModalBody = ({
  categoryName,
  pendingChange,
  setPendingChange,
}) => {
  const columns = [
    {
      name: 'Day',
      id: 'Day',
      cell: row => {
        return (
          <h1>{row}</h1>
        )
      },
    },
    {
      id: 'Start',
      name: 'Start',
      cell: dayOfWeek => {
        const dayChange = pendingChange[dayOfWeek]
        const currentSchedule = {
          start_time: dayChange ? dayChange.start_time : 'All Day',
          end_time: dayChange ? dayChange.end_time : 'All Day'
        }

        return (
          <TimePicker
            selected={currentSchedule.start_time}
            onSelect={(nextSelected)=> {
              const nextPendingChange = {
                ...currentSchedule,
                start_time: nextSelected
              }

              setPendingChange({
                ...pendingChange,
                [dayOfWeek]: nextPendingChange
              })
            }}
          />
        )
      },
    },
    {
      id: 'End',
      name: 'End',
      cell: dayOfWeek => {
        const dayChange = pendingChange[dayOfWeek]
        const currentSchedule = {
          start_time: dayChange ? dayChange.start_time : 'All Day',
          end_time: dayChange ? dayChange.end_time : 'All Day'
        }

        return (
          <TimePicker
            selected={currentSchedule.end_time}
            onSelect={(nextSelected)=> {
              const nextPendingChange = {
                ...currentSchedule,
                end_time: nextSelected
              }
              setPendingChange({
                ...pendingChange,
                [dayOfWeek]: nextPendingChange
              })
            }}
          />
        )

      },
    },
  ]

  const [expand, setExpand] = useState(false)
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  return (
    <>
      <strong><p style={{fontSize: 30}}>{categoryName}</p></strong>
      <DataTable
        columns={columns}
        customStyles={styles.dataTableStyles}
        data={days}
      />
    </>
  )
}

const ScheduleCategory = ({
  item,
  selected,
  onClick,
}) => {
  const [state, _] = useMenuContext()

  const categoryChanges = state.scheduledMenuChanges[item.id]
  const [pendingChanges, setPendingChanges] = useState(categoryChanges || {})

  const client = new Client

  return (
    <NavItem
      item={item}
      selected={selected}
      onClick={onClick}
    >
      <GGModal
        validation={()=> {
          for (let day in pendingChanges) {
            const change = pendingChanges[day]

            if(Object.keys(change).length > 0) {
              const start_time = Number(change.start_time || 0)
              const end_time = Number(change.end_time || 0)

              const incompleteOnAllDay = (start_time === 0 && end_time !== 0) || (start_time !== 0 && end_time === 0)
              const incompleteOffAllDay = (start_time === -1 && end_time !== -1) || (start_time !== -1 && end_time === -1)
              const bothOnAllDay = start_time === 0 && end_time === 0
              const bothOffAllDay = start_time === -1 && end_time === -1

              if(incompleteOnAllDay || incompleteOffAllDay) {
                return `Time selections must both be set to 'On all day' or 'Off all day' for ${day}`
              } else if(bothOffAllDay || bothOnAllDay) {
                continue
              } else if(start_time >= end_time) {
                return `Start time must be earlier than end time for ${day}`
              }
            }
          }
          return 'pass'
        }}

        onSubmit={()=> {
          client.upsertScheduledCategoryChanges(pendingChanges, item.id)
        }}
      >
        <ScheduleCategoryModalBody
          categoryName={item.name}
          pendingChange={pendingChanges}
          setPendingChange={setPendingChanges}
        />
      </GGModal>
    </NavItem>
  )
}

// TODO: extract modal component along with modal in:
//   shared-js/.../Category/Item.jsx
//   webapp/.../Category.jsx
const GGModal = ({
  children,
  onSubmit,
  validation,
}) => {
  const [show, setShow] = useState(false)
  const [validationMessage, setValidationMessage] = useState([])

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false)
        }}
        centered
      >
        <Modal.Body>
          {
            validationMessage.map(msg => {
              return <Alert key={msg} variant='warning'>{validationMessage}</Alert>
            })
          }
          { children }
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ width: 200, backgroundColor: '#fd5c63', border: 'none', fontSize: 25 }}
            onClick={() => {
              setShow(false)
            }}
          >
            <strong>Cancel</strong>
          </Button>


          <Button
            style={{
              width: 200,
              fontSize: 25,
              backgroundColor: '#007FFF'
            }}
            onClick={() => {
              const result = validation()

              if (result === 'pass') {
                setShow(false)
                onSubmit()
                setValidationMessage([])
              } else {
                setValidationMessage([ result ])
              }
            }}
          >
            <strong>Submit</strong>
          </Button>
        </Modal.Footer>

      </Modal>
      <Button
        style={{
          marginLeft: 20,
          height: 80,
          fontSize: 24,
          border: 'none',
          backgroundColor: 'white',
          color: 'black',
        }}
        onClick={() => setShow(true)}
      >
        <ClockIcon/>
      </Button>
    </>
  )
}

const NavBar = () => {
  const [state, dispatch] = useMenuContext()
  const {
    currentCategory,
    currentMenu,
    categories,
    menus,
    paymentProcessor,
  } = state

  const client = new Client
  const [reorder, setReorder] = useState(false)
  const [editCategories, setEditCategories] = useState(false)
  const buttonStyle = {
    backgroundColor: "#EBEBE0",
    fontSize: 18,
    fontWeight: 'bold',
    color: "#0876f3",
    border: "none"
  }

  const [editableCategories, setEditableCategories] = useState([])

  useEffect(()=> {

    const mappedCategories = categories.map(c => {
      return {
        id: c.category_id,
        name: c.category_name
      }
    })

    setEditableCategories(mappedCategories)
  }, [categories])

  return (
    <>
      <Row>
        <NavList
          collection={menus}
          selected={currentMenu.name}
          title={'Menus'}
          onClick={(menuID) => {
            window.location.href = `/menus/${menuID}/categories/${currentCategory.category_id}`
          }}
        />
      </Row>
      <Row>
        {
          reorder ? (
            <SortableList
              collection={state.categories}
              collectionItemID='category_id'
              displayAttribute='category_name'
              onCancel={() => setReorder(false)}
              onDragEnd={(newOrderOfCategories) => {
                dispatch({
                  type: types.UPDATE_CATEGORIES,
                  payload: newOrderOfCategories
                })
              }}
              onSave={() => {
                setReorder(false)
                const categoryOrderIDs = state.categories.map((c => c.category_id))

                client.updateCategoryOrder(
                  currentMenu.id,
                  categoryOrderIDs
                )
              }}
            />
          ) : (
            <BaseCard>
              {
                paymentProcessor === 'square' ? null : (
                  <>
                    <Button
                      style={buttonStyle}
                      onClick={()=> setEditCategories(true)}
                    >
                      Edit Categories
                    </Button>
                    <Button
                      style={buttonStyle}
                      onClick={() => {
                        client.createCategory(currentMenu.id)
                          .then(resp => {
                            dispatch({
                              type: types.ADD_CATEGORY,
                              payload: resp
                            })
                          })
                      }}>
                      New Category
                    </Button>
                  </>
                )
              }
              <Button
                style={buttonStyle}
                onClick={() => setReorder(true)}>
                Reorder
              </Button>
              {
                editCategories ? (
                  <>
                    <Form>
                      {
                        editableCategories.map(category => {
                          return (
                            <>
                              <Row>
                                <Col>
                                  <Form.Control
                                    id={category.id}
                                    value={category.name}
                                    onChange={
                                      (e) => {
                                        const nextEditableCategories = editableCategories.map(c => {
                                          if(c.id === category.id) {
                                            c.name = e.target.value
                                          }

                                          return c
                                        })

                                        setEditableCategories(nextEditableCategories)
                                      }
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Button
                                    onClick={()=> {
                                      client.deleteCategory(category.id)
                                        .then(()=> {
                                          dispatch({
                                            type: types.REMOVE_CATEGORY,
                                            payload: category.id
                                          })
                                        })
                                    }}
                                    variant='danger'
                                    style={
                                      {...buttonStyle,
                                        backgroundColor: "#bb2d3b",
                                        color: 'white'
                                      }
                                    }
                                  >Delete</Button>
                                </Col>
                              </Row>
                            </>
                          )
                        })
                      }
                    </Form>
                    <Button
                      style={buttonStyle}
                      onClick={()=> {
                        setEditCategories(false)
                        client.upsertCategoryNames(editableCategories, currentMenu.id)
                          .then(diff => {
                            dispatch({
                              type: types.UPDATE_CATEGORY_NAMES,
                              payload: diff
                            })
                          })
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={buttonStyle}
                      onClick={()=> setEditCategories(false)}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <NavList
                    ItemComponent={ScheduleCategory}
                    collection={categories}
                    selected={currentCategory && currentCategory.category_name}
                    title={'Categories'}
                    onClick={(categoryID) => {
                      window.location.href = `/menus/${currentMenu.id}/categories/${categoryID}`
                    }}
                  />

                )
              }
            </BaseCard>
          )
        }
      </Row>
    </>
  )
}

const CategoryShow = (props) => {
  const {
    menuLastUpdatedAt,
    menuIssues,
  } = props

  const client = new Client

  const [menuMeta, setMenuMeta] = useState({
    menuLastUpdatedAt,
    menuIssues,
    updating: false
  })

  const style = {
    menuUpdated: { fontSize: 18 },
    button: { fontSize: 18, fontWeight: 'bold' }
  }

  const timeStamp = new Date(menuMeta.menuLastUpdatedAt * 1000).toLocaleString()
  const menuLastUpdatedAtFormatted = menuMeta.updating ? 'updating...' : timeStamp

  return (
    <Container>
      <Row>
        <Col md={8}></Col>
        <Col md={4}>
          <p style={style.menuUpdated}>Menu last updated: {menuLastUpdatedAtFormatted}</p>

          <Button
            style={{backgroundColor: 'blue', fontWeight: 'bold'}}
            onClick={() => {
              setMenuMeta({
                ...menuMeta,
                updating: true
              })
              client.syncPartnerMenu('square', 'square', 'square')
                .then((resp) => setMenuMeta({
                  menuIssues: resp.menuIssues,
                  menuLastUpdatedAt: resp.menuLastUpdatedAt,
                  updating: false
                }))
            }}
          >
            Sync Menu
          </Button>
          {
            menuMeta.menuIssues.length === 0 ? (
              <></>
            ) : (
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><p style={{ color: "red", fontSize: 18 }}>!! Issues found synching your menu</p></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {
                        menuMeta.menuIssues.map((issue) => {
                          return <li>{issue}</li>
                        })
                      }
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )
          }
        </Col>
      </Row>
      <MenuProvider>
        <Loader {...props} />
      </MenuProvider>
    </Container>
  )
}

export default CategoryShow
