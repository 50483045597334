import React from 'react'

import samsungkiosk from '../shared/samsungcounter.png'
import mazic from '../shared/mazic.PNG'
import bigpos from '../shared/bigposcounter.png'

import { CheckCircleIcon } from '@heroicons/react/20/solid'

const tiers = [
  {
    img: mazic,
    name: 'POSBANK MAZIC 1560',
    id: 'mazic',
    features: [
      '15.6 inch screen',
      'Countertop',
      'Built in Printer',
      'No installation required',
    ],
  },
  {
    img: bigpos,
    name: 'POSBANK BIGPOS',
    id: 'bigpos',
    features: [
      '21.5 or 27 inch screen',
      'Countertop, wall-mount, floor stand',
      'Built in printer',
      'Available in white or black',
    ],
  },
  {
    img: samsungkiosk,
    name: 'Samsung KM24',
    id: 'samsung',
    features: ['24 inch screen',
      'Countertop, wall-mount, floor stand',
      'Built in printer',
      'Anti-microbial and shatter resistant screen'],
  },
]
import { CheckIcon } from '@heroicons/react/20/solid'

const Pricing = () => {

  const includedFeatures = [
    'Unlimited transactions',
    'No transactions fees',
    'Cancel anytime',
    'Square Integration',
  ]

  return (
    <div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl sm:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Subscription Plan
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Monthly Subscription</h3>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-md font-semibold leading-6 text-indigo-600">What’s included</h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-md leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">$100</span>
                    <span className="text-md font-semibold leading-6 tracking-wide text-gray-600">USD/mo</span>
                  </p>
                  <br />
                  <p className="text-md font-semibold text-gray-600">Per Kiosk</p>
                  <a
                    href="/inquiry"
                    target="_blank"
                    className="mt-8 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Book a Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // Hardware Pricing */}
      <div className="bg-white py-12 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div id="hardware-pricing" className="mx-auto max-w-4xl sm:text-center">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Choose the right Kiosk for&nbsp;you
            </h1>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-xl leading-8 text-gray-600 sm:text-center">
            Available as one time payment or a monthly payment plan
          </p>
          <div className="mt-20 flow-root">
            <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
              {tiers.map((tier) => (
                <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                  <img class='h-72' src={tier.img} alt="square integrated samsung kiosk" />
                  <h3 id={tier.id} className="text-lg font-semibold leading-7 text-gray-900">
                    {tier.name}
                  </h3>
                  <a
                    href="/inquiry"
                    target="_blank"
                    aria-describedby={tier.id}
                    className="mt-10 block rounded-md bg-indigo-600 px-3 py-2 text-center text-md font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Book a Demo
                  </a>
                  <p className="mt-10 text-md font-semibold leading-6 text-gray-900">{tier.description}</p>
                  <ul role="list" className="mt-6 space-y-3 text-md leading-6 text-gray-600">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckCircleIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Pricing
