import React from 'react'
import {
  Col,
  Row,
} from 'react-bootstrap'

import {
  BaseCard,
} from '../shared'

const Privacy = () => {
  return (
    <Row className="justify-content-md-center">
      <Col md={{ span: 8 }}>
        <h1>Privacy Policy</h1>
        <br />
        <p>Gamsa IO is committed to protecting your privacy. This privacy policy describes how Gamsa IO collects and uses the personal information you provide on our website: gamsa.io. It also describes the choices available to you regarding Gamsa IO’s use of your personal information and the steps you can take to access this information and to request that we correct or update it.</p>
        <br />
        <h3>Collection and Use of Personal Information</h3>

        <p>We collect the following personal information from you:</p>
        <ul>
          <li>Contact Information such as name, email address, phone number, etc.</li>
          <li>Unique Identifiers such as user name, ID number, account number, password</li>
        </ul>

        <p>We collect this information when you</p>
        <ul>
          <li>Set up an account</li>
          <li>Make an order</li>
        </ul>

        <p>We use this information to</p>
        <ul>
          <li>Send you a submission confirmation</li>
          <li>Respond to service requests</li>
          <li>Administer your account</li>
        </ul>
        
        <h3>Information Obtained from Third Parties</h3>
        <p>If you provide us personal information about others, or if others give us your information, we will only use that information for the specific reason for which it was provided to us.</p>
        <br />
        <h3>Information Sharing</h3>
        <p>We will share your personal information with third parties only in the ways that are described in this privacy statement. We do not sell, rent, trade, or otherwise share your personal information with third parties other than agents, service providers acting on our behalf for limited purposes.</p>
        <p>We reserve the right to disclose your personal information as required by law (e.g., to comply with a subpoena, warrant, court order, or legal process served on our website) and when we believe that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, and/or respond to a government request.</p>
        <br />
        <h3>Cookies</h3>
        <p>Cookies are very small text files that are stored on your computer when you visit certain web pages. We use cookies on our site to:</p>
        <ul>
          <li>Keep track of login name and password</li>
          <li>Provide a session timeout</li>
        </ul>
        <br />
        <h3>Log Files</h3>
        <p>As is true of most websites, we gather certain information automatically and store it in log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or page views.</p>
        <p>We use this information, which does not usually identify individual users, to analyze trends, to administer the site, to track users’ movements around the site and to gather demographic information about our user base as a whole.</p>
        <p>We may record your IP addresses when you place an order through our site in order to detect and prevent fraud.</p>
        <br />
        <h3>Correcting and Updating Your Personal Information</h3>
        <p>To review and update your personal information to ensure it is accurate, contact us at james@gamsa.io.</p>
        <br />
        <h3>Security</h3>
        <p>The security of your personal information is important to us. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
        <p>Gamsa.io does not store credit card information. Your credit card information is transmitted directly to the company handling your payment over an encrypted connection.</p>
        <p>If you have any questions about security on our website, you can contact us at james@gamsa.io.</p>
        <br />
        <h3>Notification of Privacy Statement Changes</h3>
        <p>We may update this policy from time to time as our information practices are modified or changed. If we make any material changes to this policy that affect your personal information, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this site prior to the change. We encourage you to periodically review this page for the latest information on our privacy practices.</p>

        <br />
      </Col>
    </Row>
  )
}


export default Privacy
