import React, { useState } from 'react'
import {
  Client,
  DropDown,
  ToggleSwitch,
} from '../shared'

const client = new Client

const ClaimOrderBy = ({
  locationID,
  claimOrderBy,
  claimOrderByTakeout,
  smsEligible,
}) => {
  let options = {
    autogenerated: 'Auto-Generated',
    customerName: 'Customer Name',
    orderNumber: 'Order Number',
    textMessage: 'Text Message (+ $0.02 / order)',
  }

  if (!smsEligible) {
    delete options.textMessage
  }

  const [currentCOB, setCurrentCOB] = useState(claimOrderBy)
  const [currentCOBtakeout, setCurrentCOBtakeout] = useState(claimOrderByTakeout)

  return (
    <>
      <DropDown
        selections={options}
        message='Claim orders for dine-in by:'
        currentSelected={currentCOB}
        onSelect={(selected)=> {
          setCurrentCOB(selected)

          client.updateLocation(locationID, {
            meta: {
              claim_order_by: selected
            }
          })
        }}
      />

      <br/>

      <DropDown
        selections={options}
        message='Claim orders for takeout by:'
        currentSelected={currentCOBtakeout}
        onSelect={(selected)=> {
          setCurrentCOBtakeout(selected)

          client.updateLocation(locationID, {
            meta: {
              claim_order_by_takeout: selected
            }
          })
        }}
      />
    </>
  )
}

const SpecialInstructions = ({
  enabled,
  locationID,
}) => {
  return (
    <ToggleSwitch
      checked={enabled}
      onChange={(isChecked)=> {
        client.updateLocation(locationID, {
          meta: {
            special_instructions_enabled: isChecked
          }
        })
      }}
      message='Toggle special instructions'
    />
  )
}

const TimeDuration = ({
  duration,
  locationID,
}) => {
  const options = {
    0: 0,
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    25: 25,
    30: 30
  }

  return (
    <DropDown
      selections={options}
      message='Time till order ready: '
      currentSelected={duration}
      onSelect={(selected)=> {
        client.updateLocation(locationID, {
          meta: {
            order_time_duration: selected
          }
        })
      }}
    />
  )
}

const Tipping = ({
  enabled,
  locationID,
}) => {

  return (
    <ToggleSwitch
      checked={enabled}
      onChange={()=> {
        client.updateLocation(locationID, {
          meta: {
            tipping_enabled: !enabled
          }
        })
      }}
      message='Toggle tipping'
    />

  )
}

const LoyaltyProgram = ({
  enabled,
  locationID
}) => {

  return (
    <ToggleSwitch
      checked={enabled}
      onChange={(isChecked)=> {
        client.updateLocation(locationID, {
          meta: {
            loyalty_enabled: isChecked
          }
        })
      }}
      message='Toggle Loyalty'
    />
  )
}

const OrderConfigs = ({
  locationID,
  claimOrderBy,
  claimOrderByTakeout,
  tippingEnabled,
  specialInstructionsEnabled,
  timeDuration,
  loyaltyEnabled,
  smsEligible,
}) => {
  return (
    <>
      <ClaimOrderBy
        locationID={locationID}
        claimOrderBy={claimOrderBy}
        claimOrderByTakeout={claimOrderByTakeout}
        smsEligible={smsEligible}
      />
      <br/>
      <TimeDuration
        duration={timeDuration}
        locationID={locationID}
      />
      <br/>
      <SpecialInstructions
        enabled={specialInstructionsEnabled}
        locationID={locationID}
      />
      <br/>
      <Tipping
        enabled={tippingEnabled}
        locationID={locationID}
      />
      <br/>
      <LoyaltyProgram
        enabled={loyaltyEnabled}
        locationID={locationID}
      />
    </>
  )
}

export default OrderConfigs
