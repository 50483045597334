import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
} from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  BaseCard,
  TextInput,
  SelectInput,
  Client
} from '../shared'

const SessionNew = () => {
  const initialValues = {
    email: '',
    password: '',
  }
  const client = new Client
  return (
    <Col>
      <a rel="nofollow" data-method="post" href="/users/auth/google_oauth2">
        <Button>
          <h3>Google Login / Sign up</h3>
        </Button>
      </a>
      <br/>
      <br/>
      <br/>

      - OR -

      <br/>
      <br/>

      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          email: Yup.string().email('invalid email')
        })}
        render={({
          handleSubmit,
            touched,
            errors,
            getFieldProps,
            values

        }) => {
          const meta = {
            touched,
            errors,
            getFieldProps
          }

          return (
            <Form>
              <Row>
                <TextInput
                  field='email'
                  label='Email'
                  meta={meta}
                />

                <TextInput
                  field='password'
                  label='Password'
                  type='password'
                  meta={meta}
                />

              </Row>

              <Button
                onClick={(e) => {
                  e.preventDefault()
                  handleSubmit()
                  const noErrors = Object.keys(errors).length === 0
                  const allFieldsTouched = Object
                    .keys(initialValues)
                    .every(field => {
                      return touched[field]
                    })

                  if (noErrors && allFieldsTouched) {
                    client.createUserSession(values)
                  }


                }}
              >
                Login
              </Button>
            </Form>
          )

        }}
      >
      </Formik>
    </Col>
  )
}

export default SessionNew
