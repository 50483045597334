import React, { useReducer, createContext, useContext } from "react"
export const TaxExemptionContext = createContext()


export const initialState = {
  taxExemptions: []
}

export const types = {
  LOAD_INIT: 'LOAD_INIT',
  UPDATE_TAX_EXEMPTION: 'UPDATE_TAX_EXEMPTION'
}

const reducer = (state, action) => {
  switch (action.type) {
    case types.UPDATE_TAX_EXEMPTION: {
      const updatedTaxExemption = action.payload

      const nextTaxExemptions = state.taxExemptions.map((taxExemption) => {
        return taxExemption.item_id === updatedTaxExemption.item_id ?
          updatedTaxExemption :
          taxExemption
      })


      return (
        {
          ...state,
          taxExemptions: nextTaxExemptions
        }
      )
    }
    case types.LOAD_INIT:
      const taxExemptions = action.payload

      return {
        ...state,
        taxExemptions,
      }

    default:
      throw new Error()
  }
}

export const TaxExemptionProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <TaxExemptionContext.Provider value={[state, dispatch]}>
      {props.children}
    </TaxExemptionContext.Provider>
  )
}

export const useTaxExemptionContext = () => useContext(TaxExemptionContext)
