import React, { useState } from 'react'
import { Switch } from '@headlessui/react'

import {
  Modal,
  Button,
} from 'react-bootstrap'

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

const Friction = ({
  message,
  onAccept
}) => {
  const [show, setShow] = useState(true)
  const yesButtonStyle = {
    backgroundColor: "#4274E1",
    border: "none",
    color: "#FFFFFF",
    fontSize: 18,
  }

  const noButtonStyle = {
    backgroundColor: "#D3D3D3",
    border: "none",
    color: "#000000",
    fontSize: 18,
  }

  return (
    <Modal
      show={show}
      onHide={() => { setShow(false) }}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        Confirm
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={yesButtonStyle}
          onClick={() => {
            onAccept()
            setShow(false)
          }} >
          Yes
        </Button>
        <Button
          style={noButtonStyle}
          onClick={() => {
            setShow(false)
          }}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const ToggleSwitch = ({
  checked,
  onChange,
  message,
  useFriction = false,
  frictionMessage = '',
}) => {
  const [isChecked, setIsChecked] = useState(checked)
  const [showFriction, setShowFriction] = useState(false)

  return (
    <>
      {
        showFriction ? (
          <Friction
            message={frictionMessage}
            onAccept={() => {
              setIsChecked(false)
              onChange(!isChecked)
            }}
          />
        ) : null
      }
      <Switch.Group as="div" className="flex items-center">
        <Switch
          checked={isChecked}
          onChange={() => {
            useFriction ? (
              isChecked && setShowFriction(true),
              !isChecked && onChange(true)
            ) : (
              setIsChecked(!isChecked),
              onChange(!isChecked)
            )
          }}
          className={classNames(
            isChecked ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              isChecked ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3 text-lg">
          <span className="font-medium text-gray-900">{message}</span>

        </Switch.Label>
      </Switch.Group>
    </>
  )
}

export default ToggleSwitch
