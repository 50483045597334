import React from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {
  Button,
} from 'react-bootstrap'

export function SortableListItem({
  id,
  name
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: String(id)})

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Button
      style={{backgroundColor: 'blue'}}>
        {name}
      </Button>
    </div>
  )
}
