import React from 'react'
import {
  Form
} from 'react-bootstrap'

const SelectInput = ({
  field,
  label,
  meta,
  ...props }) => {
    const {
      touched,
      errors,
      getFieldProps
    } = meta
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>

      <Form.Select
        id='structure'
        isInvalid={touched[field] && errors[field ]}
        isValid={touched[field] && !errors[field]}
        {...getFieldProps(field)}
      >
        {props.children}
      </Form.Select>
    </Form.Group>
  )
}

export default SelectInput
