import React, {
  useState,
  useEffect,
} from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Dropdown,
  ButtonGroup,
} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import {
  Client,
  formatPrice,
  BaseCard,
  DropDown,
  styles,
} from '../shared'

const Report = ({reportData}) => {
  const tempCurrentYear = Object.keys(reportData)[0]
  const [reportView, setReportView] = useState({
    currentYear: tempCurrentYear,
    currentMonth: '01',
  })


  const client = new Client

  const parseDate = (dateString) => {
    const date = new Date(dateString)

    return date.toLocaleString(
    'default',
      {month: 'long', day: 'numeric', year: 'numeric'}
    )
  }
  const columns = [
    {
      name: 'Date',
      id: 'date',
      cell: row => parseDate(row.date)
    },
    {
      name: '# of orders',
      id: 'number_of_orders',
      cell: row => row.order_count
    },
    {
      name: 'Gross Sales',
      id: 'gross_sales',
      cell: row => formatPrice(row.item_sales)
    },
    {
      name: 'Tips',
      id: 'tips',
      sortable: true,
      cell: row => formatPrice(row.tips)
    },
    {
      name: 'Taxes',
      id: 'taxes',
      cell: row => formatPrice(row.taxes)
    },
    {
      name: 'Refunds',
      id: 'refunds',
      cell: row => formatPrice(row.refunds)
    },
    {
      name: 'Discounts',
      id: 'discounts',
      cell: row => formatPrice(row.discounts)
    }
  ]

  const {
    currentYear,
    currentMonth,
  } = reportView

  const monthsMap = {
    'Jan': '01',
    'Feb': '02',
    'Mar': '03',
    'Apr': '04',
    'May': '05',
    'Jun': '06',
    'Jul': '07',
    'Aug': '08',
    'Sep': '09',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12',
  }
  const dailies = reportData[currentYear] &&
    reportData[currentYear]['months'][currentMonth] &&
    reportData[currentYear]['months'][currentMonth]['dailies'] ||
    []

  const yearlyGrossSales = reportData[currentYear]['item_sales']
  const yearlyDiscounts = reportData[currentYear]['discounts']
  const yearlyRefunds = reportData[currentYear]['refunds']
  const yearlyNetSales = yearlyGrossSales - yearlyDiscounts - yearlyRefunds
  const yearlyTaxes = reportData[currentYear]['taxes']
  const yearlyTips = reportData[currentYear]['tips']

  const monthlyGrossSales = reportData[currentYear]['months'][currentMonth]['item_sales']
  const monthlyDiscounts = reportData[currentYear]['months'][currentMonth]['discounts']
  const monthlyRefunds = reportData[currentYear]['months'][currentMonth]['refunds']
  const monthlyNetSales = monthlyGrossSales - monthlyDiscounts - monthlyRefunds
  const monthlyTaxes = reportData[currentYear]['months'][currentMonth]['taxes']
  const monthlyTips = reportData[currentYear]['months'][currentMonth]['tips']

  return (
    <BaseCard>
      <h1>Sales Summary</h1>
      <br/>
      <Row>
        <Col>
          <Row>
            <DropDown
              message={'Select Year'}
              currentSelected={currentYear}
              selections={Object.keys(reportData)}
              onSelect={(year)=> {
                setReportView({
                  ...reportView,
                  currentYear: year
                })
              }}
            />
          </Row>
          <Row>
            <h1>Yearly</h1>
            <h1>Gross Sales: {formatPrice(yearlyGrossSales)}</h1>
            <h1>Net Sales: {formatPrice(yearlyNetSales)}</h1>
            <h3>Discounts: {formatPrice(yearlyDiscounts)}</h3>
            <h3>Refunds: {formatPrice(yearlyRefunds)}</h3>
            <h1>Taxes: {formatPrice(yearlyTaxes)}</h1>
            <h1>Tips: {formatPrice(yearlyTips)}</h1>
          </Row>
        </Col>
        <Col>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="months-select">
              Month
            </Dropdown.Toggle>
            <Row>
              <h1>Gross Sales: {formatPrice(monthlyGrossSales)}</h1>
              <h1>Net Sales: {formatPrice(monthlyNetSales)}</h1>
              <h3>Discounts: {formatPrice(monthlyDiscounts)}</h3>
              <h3>Refunds: {formatPrice(monthlyRefunds)}</h3>
              <h1>Taxes: {formatPrice(monthlyTaxes)}</h1>
              <h1>Tips: {formatPrice(monthlyTips)}</h1>
            </Row>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

            <Dropdown.Menu>
              {
                Object.keys(monthsMap).map(month => {
                  return (
                    <Dropdown.Item
                      eventKey={month}
                      onClick={()=> {
                        const parsedMonth = monthsMap[month]
                        setReportView({
                          ...reportView,
                          currentMonth: parsedMonth
                        })
                      }}
                    >
                      {month}
                    </Dropdown.Item>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      {
        currentYear && currentMonth ? (
          <DataTable
            fixedHeader
            customStyles={styles.dataTableStyles}
            columns={columns}
            data={dailies}
            pagination
          />
        ) : null
      }
    </BaseCard>
  )
}

const NoData = () => {
  return (
    <h1>No payments found</h1>
  )
}

const ReportsIndex = ({ reportData }) => {
  return (
    <>
      {
        Object.keys(reportData).length === 0 ? (
          <NoData/>
        ) : (
          <Report reportData={reportData}/>
        )
      }
    </>
  )
}

export default ReportsIndex
