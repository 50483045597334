import React, { useState } from 'react'
import {
  Modal,
  Button,
} from 'react-bootstrap'

const GModal = ({
  onShow,
  onHide = () => { },
  Header = ()=> <></>,
  Footer = ()=> <></>,
  Body,
  initData,
  onCancel = () => { },
  onSubmit = () => { },
  openButtonStyle = { backgroundColor: '#F0F8FF', color: 'black', width: 450, height: 80, boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)", borderRadius: 20,  fontSize: 35, fontWeight: 600, paddingTop: 12 }
}) => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState(initData)

  const {
    openModalMessage
  } = data

  return (
    <>
      <Modal
        show={show}
        onShow={onShow}
        onHide={() => {
          onHide()
          setShow(false)
        }}
        centered
        size="lg"
      >
        <Modal.Header>
          <Header
            state={{
              data,
              setData,
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <Body
            state={{
              data,
              setData,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Footer
            onCancel={() => {
              setShow(false)
              setData(initData)
              onCancel()
            }}
            onSubmit={() => {
              setShow(false)
              setData(initData)
              onSubmit(data)
            }}
            state={{
              data,
              setData,
            }}
          />
        </Modal.Footer>
      </Modal>
      <div style={openButtonStyle}
        className="text-center"
        onClick={() => {
          setShow(true)
        }}>
        <span >{openModalMessage}</span>
      </div>
    </>
  )
}

export default GModal
