import React, { useState } from 'react'

import {
  Row
} from 'react-bootstrap'

const DropDown = ({
  selections,
  message,
  currentSelected,
  onSelect = ()=> {},
}) => {
  const int = Math.floor(Math.random() * 10000)

  return (
    <Row>
      <label for={int}style={{fontSize: 18}}>{message}</label>

      <select
        style={{width: 300}}
        name="dropdown"
        id={int}
        defaultValue={currentSelected}
        onChange={(e)=> {
          const nextSelected = e.target.value

          if(currentSelected !== nextSelected) {
            onSelect(nextSelected)
          }
        }}
      >
        {
          Object.keys(selections).map(selection => {
            return (
              <option value={selection}>
                {selections[selection]}
              </option>
            )
          })
        }
      </select>
    </Row>
  )
}

export default DropDown
