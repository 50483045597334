import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  Button,
  Row,
  Col,
  Form,
} from 'react-bootstrap'

import {
  BaseCard,
  styles,
  Client,
  SortableList,
  GModal,
  EditIcon,
  DeleteIcon,
} from '../shared'

import {
  useMenuContext,
  types
} from '../state/MenuProvider'

const CreateOptionForm = ({
  data,
  setData
}) => {

  const {
    option_name,
    option_min,
    option_max,
    choices,
  } = data.option

  const client = new Client
  const [_, dispatch] = useMenuContext()
  return (
    <Form>
      <BaseCard>
        <Row>
          <Col md={6}>
            <Form.Label>Option Name</Form.Label>
            <Form.Control
              value={option_name}
              aria-label='option-name'
              onChange={
                (e) => {
                  setData({
                    ...data,
                    option: {
                      ...data.option,
                      option_name: e.target.value
                    }
                  })
                }
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label>Min Required</Form.Label>
            <Form.Control
              value={option_min}
              aria-label='option-min'
              onChange={
                (e) => {
                  setData({
                    ...data,
                    option: {
                      ...data.option,
                      option_min: e.target.value
                    }
                  })

                }
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label>Max Allowed</Form.Label>
            <Form.Control
              value={option_max}
              aria-label='option-max'
              onChange={
                (e) => {
                  setData({
                    ...data,
                    option: {
                      ...data.option,
                      option_max: e.target.value
                    }
                  })
                }
              }
            />
          </Col>
        </Row>
      </BaseCard>
      <BaseCard>
        <Row>
          <Col>
            <h3>Choices</h3>
          </Col>
          <Col>
            <Button
              style={{backgroundColor: '#007FFF'}}
              onClick={() => {
                setData({
                  ...data,
                  option: {
                    ...data.option,
                    choices: [
                      ...data.option.choices,
                      {
                        choice_name: '',
                        choice_price: '',
                      }
                    ]
                  }
                })
              }}
            >Add Choice</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            Name
          </Col>
          <Col>
            Price
          </Col>
        </Row>
        <Row>
          {
            choices.map((choice, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <Form.Control
                      value={choice.choice_name}
                      onChange={
                        (e) => {
                          setData({
                            ...data,
                            option: {
                              ...data.option,
                              choices: Object.assign(
                                [],
                                choices,
                                {
                                  [index]: {
                                    ...choices[index],
                                    choice_name: e.target.value
                                  }
                                }
                              )
                            }
                          })
                        }
                      }
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      value={choice.choice_price}
                      onChange={
                        (e) => {
                          setData({
                            ...data,
                            option: {
                              ...data.option,
                              choices: Object.assign(
                                [],
                                choices,
                                {
                                  [index]: {
                                    ...choices[index],
                                    choice_price: e.target.value
                                  }
                                }
                              )
                            }
                          })
                        }
                      }
                    />
                  </Col>
                  <Col>
                    <DeleteIcon
                      onClick={() => {
                        const choiceID = choice.choice_id
                        const optionID = choice.option_id

                        client.deleteChoice(choiceID)
                          .then(() => {

                            const nextChoices = data
                              .option
                              .choices
                              .filter(choice => choice.choice_id !== choiceID)

                            const nextOption = {...data.option}
                            nextOption.choices = nextChoices

                            dispatch({
                              type: types.UPDATE_ITEM_OPTION,
                              payload: nextOption
                            })

                            setData({
                              ...data,
                              option: {
                                ...data.option,
                                choices: nextChoices
                              }
                            })
                          })
                      }}
                    />
                  </Col>
                </Row>

              )
            })
          }
        </Row>
      </BaseCard>
    </Form>
  )
}

const ReorderOptions = ({
  item,
  children
}) => {
  const [reorder, setReorder] = useState(false)
  const client = new Client
  const [_, dispatch] = useMenuContext()

  return (
    <>
      <Button
        style={{ backgroundColor: 'blue', fontWeight: 'bold' }}
        onClick={() => setReorder(true)} >
        Reorder
      </Button>
      {
        reorder ? (
          <SortableList
            collection={item.options}
            collectionItemID={'option_id'}
            displayAttribute={'option_name'}
            onCancel={() => setReorder(false)}
            onDragEnd={(newOrderOfOptions) => {
              dispatch({
                type: types.UPDATE_ITEM,
                payload: {
                  updatedItem: {
                    id: item.item_id,
                    name: item.item_name,
                    item_description: item.item_description,
                    price: item.item_price,
                    item_photo_url: item.item_photo_url,
                    item_thumbnail_url: item.item_thumbnail_url,
                  },
                  selectedOptions: newOrderOfOptions
                }
              })
            }}
            onSave={() => {
              setReorder(false)
              const optionOrderIDs = item.options.map(option => option.option_id)

              client.editOptionOrder(item.item_id, optionOrderIDs)
            }}
          />

        ) : (
          children
        )
      }
    </>
  )
}

const EditOption = ({
  item,
  optionInitData = {
    option_name: '',
    option_min: 0,
    option_max: 0,
    choices: [],
    item_id: item.item_id,
  },
  onSubmit,
  openModalMessage,
}) => {
  const Footer = ({
    onCancel,
    onSubmit,
    state
  }) => {

    return (
      <>
        <Row>
          <Col>
            <Button
              style={{
                fontSize: '30px',
                height: '70px',
                width: '200px',
                backgroundColor: 'grey'
              }}
              onClick={onCancel} >
              <strong>Cancel</strong>
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                fontSize: '30px',
                height: '70px',
                width: '200px',
                backgroundColor: 'blue'
              }}
              onClick={() => {
                const option = state.data.option
                onSubmit(option)
              }}
            >
              <strong>Submit</strong>
            </Button>
          </Col>
        </Row>
      </>
    )
  }
  const Body = ({
    state
  }) => {
    const {
      data,
      setData,
    } = state
    return (
      <CreateOptionForm
        data={data}
        setData={setData}
      />
    )
  }

  const Header = () => {
    return (
      <h1>Add Option</h1>
    )
  }

  return (
    <GModal
      Header={Header}
      Body={Body}
      Footer={Footer}
      initData={{
        openModalMessage: openModalMessage,
        option: optionInitData,
      }}
      onSubmit={(option) => {
        onSubmit(option)
      }}
      openButtonStyle={{ backgroundColor: 'blue', fontWeight: 'bold', color: 'white' }}
    />
  )
}

const OptionsTable = ({
  item,
  setSelectedOptions,
}) => {
  const [state, dispatch] = useMenuContext()

  const [show, setShow] = useState(false)
  const [optionForModal, setOptionForModal] = useState(null)
  const client = new Client

  const columns = [
    {
      id: 'option',
      name: 'Option',
      selector: row => row.option_name,
      sortable: true,
    },

    {
      id: 'optionMinMax',
      name: 'Min / Max Required',
      selector: row => `${row.option_min} / ${row.option_max}`,
      sortable: true,
    },
    {
      id: 'choice',
      name: 'Choice',
      grow: 1,
      selector: row => {
        const choices = row.choices.map(c => {
          return c.choice_name
        })

        return choices.join(', ')
      },
      sortable: true,
    },
    {
      id: 'edit',
      name: 'edit',
      omit: state.paymentProcessor === 'square',
      cell: row => {
        return (
          <EditOption
            openModalMessage={"Edit Option"}
            optionInitData={row}
            item={item}
            onSubmit={(data)=> {
              const { option } = data
              client.updateOption(option)
                .then(() => {
                  let nextOption = {...option}
                  nextOption['item_id'] = item.item_id

                  dispatch({
                    type: types.UPDATE_ITEM_OPTION,
                    payload: nextOption
                  })
                })
            }}
          />

        )
      }
    },
    {
      id: 'delete',
      name: 'Delete',
      omit: state.paymentProcessor === 'square',
      selector: row => {
        return (
          <Button
            style={{
              backgroundColor: '#ff6666',
              fontWeight: 'bold',
              color: 'white'
            }}
            onClick={() => {
              const optionID = row.option_id

              client.deleteOption(optionID)
                .then(()=> {
                  const payload = {
                    itemID: item.item_id,
                    optionID
                  }

                  dispatch({
                    type: types.REMOVE_OPTION,
                    payload
                  })
                })
            }}
          >
            Delete
          </Button>
        )
      },
      sortable: true,
    },

  ]

  return (
    <div>
      {
        state.paymentProcessor === 'square' ? null : (
          <EditOption
            openModalMessage={"Add Option"}
            item={item}
            onSubmit={(option)=> {
              client.createOption(option)
                .then((nextOptions) => {
                  const payload = {
                    nextOptions,
                    item,
                  }

                  dispatch({
                    type: types.ADD_ITEM_OPTION,
                    payload
                  })
                })
            }}
          />

        )
      }
      <ReorderOptions item={item}>
        <div data-test-id='options'>
          <DataTable
            keyField='option_id'
            customStyles={styles.dataTableStyles}
            columns={columns}
            data={item.options}
            onSelectedRowsChange={(tableState) => {
              setSelectedOptions(tableState.selectedRows)
            }}
            customStyles={styles.dataTableStyles}
          />

        </div>
      </ReorderOptions>

    </div>
  )
}

export default OptionsTable
