import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  Button,
  Form,
  Tab,
  Row,
  Col,
  Nav,
} from 'react-bootstrap'

import '../shared/style.css'
import Client from '../shared/client.js'
import {
  useTaxExemptionContext,
  types,
} from '../state/TaxExemptionProvider'

const CheckButton = ({
  taxExemption
}) => {
  const [_, dispatch] = useTaxExemptionContext()
  const {
    dine_in,
    take_out
  } = taxExemption

  return (
    <>
      <Form.Check
        type='checkbox'
        checked={take_out}
        onChange={() => {
          dispatch({
            type: types.UPDATE_TAX_EXEMPTION,
            payload: {
              ...taxExemption,
              take_out: !take_out
            }
          })
        }}
      />
      <div style={{marginRight: 50}}>
      </div>
      <Form.Check
        type='checkbox'
        checked={dine_in}
        onChange={() => {
          dispatch({
            type: types.UPDATE_TAX_EXEMPTION,
            payload: {
              ...taxExemption,
              dine_in: !dine_in
            }
          })
        }}
      />
    </>
  )
}

const groupByCategory = (list) => {
  return list.reduce((memo, item ) => {
    const {
      category_name: cat
    } = item

    if(memo[cat]) {
      memo[cat].push(item)
    } else {
      memo[cat] = [item]
    }

    return memo
  }, {})
}


const EditTaxExemptions = ({
  currentCategory,
}) => {
  const client = new Client
  const [state, dispatch] = useTaxExemptionContext()
  const [saving, setSaving] = useState(false)

  const {
    taxExemptions,
  } = state

  const groupedExemptions = groupByCategory(taxExemptions)
  const categories = Object.keys(groupedExemptions)

  const columns = [
    {
      name: 'Item',
      selector: row => row.item_name,
      id: "itemName",
      sortable: true,
      style: { fontSize: 16}
    },
    {
      name: 'Category',
      selector: row => row.category_name,
      id: "categoryName",
      sortable: true,
      style: { fontSize: 16 }
    },
    {
      name: 'Take Out  |  Dine In',
      id: "takeout",
      style: { fontSize: 16 },
      cell: row => <CheckButton taxExemption={row}/>
    },
  ]

  const indexOfCurrentCatgory = categories.indexOf(currentCategory)

  return (
    <Tab.Container id="left-tabs-tax-exemptions" activeKey={categories[indexOfCurrentCatgory]}>
      <Row>
        <Col sm={2}>
          <Nav
            variant="pills"
            className="flex-column"
            onSelect={(category) => {
              window.location.href = `?tax_exemptions=${escape(category)}`
            }}
          >
            {
              categories.map(category => {
                return (
                  <Nav.Item key={category}>
                    <Nav.Link eventKey={category}>{category}</Nav.Link>
                  </Nav.Item>
                )
              })
            }
          </Nav>
        </Col>
        <Col sm={9}>
          <Form>
            <DataTable
              columns={columns}
              data={groupedExemptions[currentCategory]}
              persistTableHead
              fixedHeader
            />
            <br/>
            <button
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              style={{fontSize: 18, fontWeight: 'bold'}}
              type="submit"
              onClick={(e)=> {
                e.preventDefault()
                setSaving(true)
                client.updateTaxExemptions(taxExemptions)
                  .then(()=> setSaving(false))
              }}
            >
              { saving ? 'Saving...' : 'Save'}
            </button>
          </Form>
        </Col>
      </Row>
    </Tab.Container>
  )
}

const TaxExemptions = ({
  taxExemptions,
  currentCategory,
}) => {
  const [_, dispatch] = useTaxExemptionContext()

  useEffect(()=> {
    dispatch({
      type: types.LOAD_INIT,
      payload: taxExemptions,
    })
  }, [])

  return (
    <div class="card border-light mb-3" style={{ margin: '50px' }}>
      <h5 class="card-header"><strong>Tax Exemptions</strong></h5>
      <div class="card-body">
        <EditTaxExemptions
          currentCategory={currentCategory}
        />
      </div>
    </div>

  )
}

export default TaxExemptions
