import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Form,
  Button,
  Row,
  Col,
  Container,
} from 'react-bootstrap'

import {
  BaseCard,
  TextInput,
  SelectInput,
  Client
} from '../shared'

import StripeButton from '../shared/stripe-hover.svg'

const StripeSignup = ({
  client
}) => {
  const initialValues = {
    businessName: '',
    phone: '',
    structure: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
    url: '',
    businessDescription: '',
    statementDescriptor: '',
  }

  const [submitting, setSubmitting] = useState(false)

  return (
    <Container>
      <h1>Connect with Stripe</h1>
      <div>
        Gamsa uses Stripe to better handle secure payments.
      </div>
      <div>
        Add your business information and continue to Stripe to connect payments.
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          businessName: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
          phone: Yup.string()
          .max(12, 'Must be 12 characters or less')
          .required('Required'),
          structure: Yup.string()
          .oneOf([
            'sole_proprietorship',
            'single_member_llc',
            'multi_member_llc',
            'private_company',
            'private_partnership',
            'private_corporation',
          ])
          .required('Required'),
          line1: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
          line2: Yup.string()
          .max(20, 'Must be 20 characters or less'),
          city: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
          state: Yup.string()
          .length(2, 'Must be a 2 character abbreviation (ex: CA)')
          .required('Required'),
          postalCode: Yup.string()
          .length(5, 'Must be 5 numbers')
          .required('Required'),
          url: Yup.string()
          .max(30, 'Must be 30 characters or less')
          .required('Required'),
          businessDescription: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
          statementDescriptor: Yup.string()
          .min(5, 'Must be atleast 5 characters')
          .required('Required'),
        })}
        render={({
          handleSubmit,
            touched,
            errors,
            getFieldProps,
            values
        }) => {
          const meta = {
            touched,
            errors,
            getFieldProps
          }
          return (
            <Form onSubmit={(e)=> {
            }}>
              <BaseCard>
                <Row>

                  <Col md={5}>
                    <TextInput
                      field='businessName'
                      label='Business Name'
                      meta={meta}
                    />
                  </Col>

                  <Col md={3}>
                    <SelectInput
                      field='structure'
                      label='Business Structure'
                      meta={meta}
                    >
                      <option>Select...</option>
                      <option value="sole_proprietorship">Sole Proprietorship</option>
                      <option value="single_member_llc">Single Member LLC</option>
                      <option value="multi_member_llc">Multi Member LLC</option>
                      <option value="private_company">Private Company</option>
                      <option value="private_partnership">Private Partnership</option>
                      <option value="private_corporation">Private Corporation</option>
                    </SelectInput>
                  </Col>
                  <Col>
                    <TextInput
                      field='url'
                      label='Business URL'
                      meta={meta}
                    />
                  </Col>

                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      field='businessDescription'
                      label='Business Description'
                      meta={meta}
                    />
                  </Col>

                  <Col md={6}>
                    <TextInput
                      field='statementDescriptor'
                      label='Statement Descriptor'
                      meta={meta}
                    />
                  </Col>
                </Row>
              </BaseCard>

              <BaseCard>
                <Row>
                  <TextInput
                    field='line1'
                    label='Street Address 1'
                    meta={meta}
                  />
                </Row>

                <Row>
                  <TextInput
                    field='line2'
                    label='Street Address 2'
                    meta={meta}
                  />
                </Row>

                <Row>
                  <Col md={6}>
                    <TextInput
                      field='city'
                      label='City'
                      meta={meta}
                    />
                  </Col>

                  <Col md={3}>
                    <TextInput
                      field='state'
                      label='State'
                      meta={meta}
                    />
                  </Col>

                  <Col md={3}>
                    <TextInput
                      field='postalCode'
                      label='Zip Code'
                      meta={meta}
                    />
                  </Col>
                </Row>

                <Row>
                  <TextInput
                    field='phone'
                    label='Phone'
                    meta={meta}
                  />
                </Row>
              </BaseCard>

              <BaseCard>
                {
                  submitting ? (
                    <Button disabled>Submitting...</Button>
                  ) : (
                    <Button
                      type="image"
                      src={StripeButton}
                      onClick={(e) => {
                        e.preventDefault()
                        handleSubmit()
                        setSubmitting(true)

                        const noErrors = Object.keys(errors).length === 0
                        const allFieldsTouched = Object
                          .keys(initialValues)
                          .every(field => {
                            return touched[field]
                          })

                        if (noErrors && allFieldsTouched) {
                          client.createPaymentProcessorAccount(values)
                            .then(resp => {
                              if(resp.error_message) {
                                setSubmitting(false)
                                alert(resp.error_message)
                              } else {
                                window.location.href = resp.kyc_URL
                              }
                            })
                            .catch(error => {
                              console.log('Error creating account: ', error)
                            })
                        }
                      }}
                    >
                      Submit
                    </Button>
                  )
                }
              </BaseCard>
            </Form>
          )
        }}
      />
    </Container>
  );
};

const BusinessNew = ({
  squareAppID,
  squareBaseURL,
  terminals,
}) => {
  const client = new Client

  return (
    <BaseCard>
      <StripeSignup
        client={client}
      />
    </BaseCard>
  )
}

export default BusinessNew
