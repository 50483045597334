import React, { useState } from 'react'
import {
  Button,
  Nav,
  Navbar,
  NavDropdown,
  Container,
} from 'react-bootstrap'


import { Dialog } from '@headlessui/react'

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import gamsa from '../shared/gamsaiologo.png'

const LoggedIn = ({
  currentBusiness
}) => {

  return (
    <>
      <Nav className="me-auto">
        <Nav.Link eventKey="menus" href="/menus/0/categories/0">
          Menus
        </Nav.Link>
        <Nav.Link eventKey="secrets" href={`/businesses/${currentBusiness.id}/locations`}>
          Locations
        </Nav.Link>
        <Nav.Link eventKey="settings" href="/settings/main">
          Settings
        </Nav.Link>
        <Nav.Link eventKey="helpcenter" href="https://gamsa-io.reamaze.com">
          Help Center
        </Nav.Link>
        <Nav.Link eventKey="menus" rel="nofollow" data-method="delete" href="/users/sign_out">
          Log Out
        </Nav.Link>
      </Nav>

    </>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const LinkItem = ({
  href,
  text,
  selected,
}) => {
  const hilighted = "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
  const unhilighted = "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
  const style = selected ? hilighted : unhilighted
  return (
    <Disclosure.Button
      as="a"
      href={href}
      className={style}
    >
      {text}
    </Disclosure.Button>

  )
}

const LinkItemLaptop = ({
  text,
  href,
  selected,
  onClick,
}) => {

  const unhilighted = "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-lg font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"

  const hilighted = "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-lg font-medium text-gray-900"

  const style = selected ? hilighted : unhilighted

  return (
    <a
      href={href}
      className={style}
      onClick={onClick}
    >
      {text}
    </a>
  )
}


const LoggedOut = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigation = [
    { name: 'Home', href: '/', target: '' },
    { name: 'About Us', href: '/about', target: '' },
    { name: 'Pricing', href: '/pricing', target: '' },
    { name: 'Book a Demo', href: '/inquiry', target: "_blank" },
  ]

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex items-center gap-x-12">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Gamsa.IO</span>
            <img className="h-14 w-auto" src={gamsa} alt="" />
          </a>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} target={item.target} className="text-lg font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex">
          <a href="/users/sign_in" className="text-lg font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src={gamsa}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="/users/sign_in"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

const Header = ({
  currentBusiness,
  currentPath,
}) => {
  return (
    <>
      {
        currentBusiness ? (
          <LoggedIn currentBusiness={currentBusiness} />
        ) : (
          <LoggedOut
            currentPath={currentPath}
          />
        )
      }
    </>
  )
}

export default Header
