import React from 'react'
import {
  Col,
  Row,
} from 'react-bootstrap'

import {
  BaseCard,
} from '../shared'

const TermsOfService = () => {
  return (
    <Row className="justify-content-md-center">
      <Col md={{ span: 8 }}>
        <h1>Terms of Service</h1>
        <br />
        <h3><strong>General Terms of Service</strong></h3>
        <b>Last updated: January 7, 2024</b>
        <p>This page describes the general terms that apply to your use of any of our services. By using our services you agree to these terms on top of any other terms that apply to a specific service you are using. If you use our services on behalf of your company, your company agrees to them too.</p>
        <br/>
        <p>These General Terms of Service (“General Terms”) are a legal agreement between you, as a current or prospective customer of Gamsa IO’s services (“you,” “your”) and govern your use of Gamsa IO’s services, including mobile applications, websites, software, cloud-based solutions, hardware, and other products and services (collectively, the “Services”).</p>
        <br/>
        <p> By using any of the Services, you agree to these General Terms and any policies referenced within (“Policies”) which are collectively incorporated herein by reference. You also agree to any additional terms specific to Services you use (“Additional Terms”), such as those listed below, which become part of your agreement with us (collectively, the “Terms”). If you are using the Services on behalf of a business, you represent to us that you have authority to bind that business or entity to these Terms, and that business accepts these Terms. You should read all of our Terms carefully.</p>
        <br />
        <h3><strong>Gamsa IO Account Registration</strong></h3>
        <p>Summary:You will provide accurate personal information during registration and keep the information current. We can suspend or terminate your account if you provide incomplete or false information.</p>
        <p>You are responsible for all activity on your account.</p>
        <br/>
        <p>You must open an account with us (a “Gamsa IO Account”) to use the Services. During registration we will ask you for information, which may include but is not limited to, your name and other personal information. You must provide accurate and complete information in response to our questions, and you must keep that information current. You are fully responsible for all activity that occurs under your Gamsa IO Account, including for any actions taken by persons to whom you have granted access to the Gamsa IO Account. We reserve the right to change the account type, suspend or terminate the Gamsa IO Account of anyone who provides inaccurate, untrue, or incomplete information, or who fails to comply with the account registration requirements.</p>
        <br />
        <h3><strong>Revisions, Disclosures and Notices</strong></h3>
        <p>Summary: we can make updates to our terms of service at any time, and we’ll notify you of these updates as appropriate. By continuing to use our services after these updates, you agree to the revised terms.</p>
        <p>You also agree to receive notices from us electronically.</p>
        <br/>
        <p>We may amend the Terms at any time with notice that we deem to be reasonable under the circumstances, by posting the revised version on our website or communicating it to you through the Services (each a “Revised Version”). The Revised Version will be effective as of the time it is posted, but will not apply retroactively. Your continued use of the Services after the posting of a Revised Version constitutes your acceptance of such Revised Version.</p>
        <br/>
        <p>We may provide disclosures and notices required by law and other information about your Gamsa IO Account to you electronically, by posting it on our website, pushing notifications through the Services, or by emailing it to the email address listed in your Gamsa IO Account or that you otherwise provided to Gamsa IO. Electronic disclosures and notices have the same meaning and effect as if we had provided you with paper copies. Such disclosures and notices are considered received by you within twenty-four (24) hours of the time posted to our website, or within twenty-four (24) hours of the time emailed to you unless we receive notice that the email was not delivered. If you wish to withdraw your consent to receiving electronic communications, contact Gamsa IO support. If we are not able to support your request, you may need to terminate your Gamsa IO Account.</p>
        <br />
        <h3><strong>Restrictions</strong></h3>
        <p>Summary: if you want to use our services, you can’t do certain bad things. If we think you are doing these bad things, we have the right to share your information with law enforcement.</p>
        <br/>
        <p>You may not, nor may you permit any third party, directly or indirectly, to:</p>
        <ul>
          <li>*export the Services, which may be subject to export restrictions imposed by US law, including US Export Administration Regulations (15 C.F.R. Chapter VII);</li>
          <li>*engage in any activity that may be in violation of regulations administered by the United States Department of the Treasury’s Office of Foreign Asset Control (31 C.F.R. Parts 500-599). Prohibited activity includes but is not limited to the provision of Services to or for the benefit of a jurisdiction, entity, or individual blocked or prohibited by relevant sanctions authorities, including but not limited to activities in Iran, Cuba, North Korea, Syria, or the Crimean Region of the Ukraine. If found to be in apparent violation of these restrictions, your account could be terminated and your funds could be held for an indefinite period of time;</li>
          <li>*access or monitor any material or information on any Gamsa IO system using any manual process or robot, spider, scraper, or other automated means;</li>
          <li>*except to the extent that any restriction is expressly prohibited by law, violate the restrictions in any robot exclusion headers on any Service, work around, bypass, or circumvent any of the technical limitations of the Services, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services;</li>
          <li>*perform or attempt to perform any actions that would interfere with the proper working of the Services, prevent access to or use of the Services by our other customers, or impose an unreasonable or disproportionately large load on our infrastructure;</li>
          <li>*copy, reproduce, alter, modify, create derivative works, publicly display, republish, upload, post, transmit, resell or distribute in any way material, information or Services from Gamsa IO;</li>
          <li>*use and benefit from the Services via a rental, lease, timesharing, service bureau or other arrangement;</li>
          <li>*transfer any rights granted to you under these General Terms;</li>
          <li>*use the Services in a way that distracts or prevents you from obeying traffic or safety laws;</li>
          <li>*use the Services for the sale of firearms, firearm parts, ammunition, weapons or other devices designed to cause physical harm;</li>
          <li>*use the Services for any illegal activity or goods or in any way that exposes you, other Gamsa IO users, our partners, or Gamsa IO to harm; or</li>
          <li>*otherwise use the Services except as expressly allowed under these Terms.</li>
        </ul>
        <br/>
        <p>If we reasonably suspect that your Gamsa IO Account has been used for an unauthorized, illegal, or criminal purpose, you give us express authorization to share information about you, your Gamsa IO Account, and any of your transactions with law enforcement.</p>
        <br />
        <h3><strong>Compatible Mobile Devices and Third Party Carriers</strong></h3>
        <p>We do not warrant that the Services will be compatible with your mobile device or carrier. Your use of the Services may be subject to the terms of your agreements with your mobile device manufacturer or your carrier. You may not use a modified device to use the Services if the modification is contrary to the manufacturer’s software or hardware guidelines, including disabling hardware or software controls—sometimes referred to as “jail broken.”</p>
        <br />
        <h3><strong>Your Content</strong></h3>
        <p>Summary: You retain all rights to your content when you upload it into our services, but you do give us broad rights to use, modify, display your content in our services. You can see specifics on the rights you grant us below.</p>
        <p>Also, anything you provide us or make available to the public through our services must not contain anything that we think is objectionable (e.g., illegal, obscene, hateful or harmful to you, our customers or us). We can remove any content at any time.</p>
        <br/>
        <p>The Services may include functionality for uploading or providing suggestions, recommendations, feedback, stories, photos, documents, logos, products, loyalty programs, promotions, advertisements and other materials or information (“Content”).</p>
        <br/>
        <p>You grant us and our subsidiaries, affiliates, and successors a worldwide, non-exclusive, royalty-free, fully-paid, transferable, irrevocable, perpetual, and sub-licensable right to use, reproduce, modify, adapt, publish, prepare derivative works of, distribute, publicly perform, and publicly display your Content throughout the world in any media for any reason, including to provide, promote, and/or incorporate into the Services. You retain all rights in your Content, subject to the rights you granted to us in these General Terms. You may modify or remove your Content via your Gamsa IO Account or by terminating your Gamsa IO Account, but your Content may persist in historical, archived or cached copies and versions thereof available on or through the Services.</p>
        <br/>
        <p>You will not upload or provide Content or otherwise post, transmit, distribute, or disseminate through the Services any material that: (a) is false, misleading, unlawful, obscene, indecent, lewd, pornographic, defamatory, libelous, threatening, harassing, hateful, abusive, or inflammatory; (b) encourages conduct that would be considered a criminal offense or gives rise to civil liability; (c) breaches or infringes any duty toward or rights of any person or entity, including rights of publicity, privacy or Intellectual Property Rights; (d) contains corrupted data or any other harmful, disruptive, or destructive files; (e) advertises products or services competitive with Gamsa IO’s or its partners’ products and services, as determined by us in our sole discretion; or (f) in our sole judgment, is objectionable, restricts or inhibits any person or entity from using or enjoying any portion of the Services, or which may expose Gamsa IO, its affiliates or its customers or other persons to harm or liability of any nature.</p>
        <br/>
        <p>Although we have no obligation to monitor any Content, we have absolute discretion to remove Content at any time and for any reason without notice. Gamsa IO may also monitor such Content to detect and prevent fraudulent activity or violations of Gamsa IO’s General Terms. You understand that by using the Services, you may be exposed to Content that is offensive, indecent, or objectionable. We take no responsibility and assume no liability for any Content, including any loss or damage to any of your Content.</p>
        <br />
        <h3><strong>Security</strong></h3>
        <p>Summary: We take security incredibly seriously, but can’t guarantee that bad actors will not gain access to your personal information. You need to do your part by keeping your passwords safe, being smart about who has access to your account and which of your devices can access our services, and letting us know if you think an unauthorized person is using your account.</p>
        <p>If there is ever a dispute about who owns your account, we are the decider.</p>
        <br/>
        <p>We have implemented technical and organizational measures designed to secure your personal information from accidental destruction, loss, alteration and from unauthorized access, use, alteration, or disclosure. However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures or use your personal information for improper purposes. You provide your personal information at your own risk.</p>
        <p>You are solely responsible for safeguarding your password and for restricting access to the Services from your compatible mobile devices and computer(s). You will immediately notify us of any unauthorized use of your password or Gamsa IO Account or any other breach of security. You will immediately take all reasonable steps to mitigate the effects of a security breach and will cooperate with Gamsa IO and provide all information requested by Gamsa IO to remediate the breach. Any assistance provided by Gamsa IO in relation to a security breach does not in any way operate as acceptance or acknowledgement that Gamsa IO is in any way responsible or liable to you or any other party in connection with such breach.</p>
        <p>In the event of any dispute between two or more parties as to account ownership, we will be the sole arbiter of such dispute in our sole discretion. Our decision (which may include termination or suspension of any Gamsa IO Account subject to dispute) will be final and binding on all parties.</p>
        <br />
        <h3><strong>Privacy</strong></h3>
        <p>By using any of our Services as a Gamsa IO seller, you acknowledge our data practices that apply to you in our Privacy Policy. The Privacy Policy explains how Gamsa IO collects, uses and protects the personal information you provide to us where Gamsa IO makes use of your personal data to provide you with the Services or for its own purposes. You are required to familiarize yourself with the Privacy Policy prior to using the Services.</p>
        <br/>
        <p>Your Customers’ and Employees’ Personal Information Gamsa IO will process certain of your customers’ or employees’ personal data on behalf of your business as a service provider (“data processor”). In such circumstances, you agree that you will comply with the data protection laws applicable to you and will provide data subjects with information on the processing of their personal information which satisfies the transparency requirements of such data protection laws and which ensures that personal data may be processed fairly, lawfully and in a transparent manner.</p>
        <br />
        <h3><strong>Communications</strong></h3>
        <p>Summary: You consent to us sending messages to your email or mobile devices about your account or our services, which may include marketing. You can opt out of communications in ways that are specific to that communication method, such as by unsubscribing from emails, or replying to text messages with ‘STOP’.</p>
        <br/>
        <p>You consent to accept and receive communications from us, including e-mail, text messages, calls, and push notifications to the cellular telephone number you provide to us when you sign-up for a Gamsa IO account or update the contact information associated with your account. Such communications may include, but are not limited to requests for secondary authentication, receipts, reminders, notifications regarding updates to your account or account support, and marketing or promotional communications. You acknowledge that you are not required to consent to receive promotional texts or calls as a condition of using the Services. Call and text message communications may be generated by automatic telephone dialing systems. Standard message and data rates applied by your cell phone carrier may apply to the text messages we send you.</p>
        <br/>
        <p>You may opt-out of receiving promotional email communications we send to you by following the unsubscribe options on such emails. You may opt out of any promotional phone calls by informing the caller that you would not like to receive future promotional calls. You may only opt-out of text messages from Gamsa IO by replying STOP. You acknowledge that opting out of receiving communications may impact your use of the Services.</p>
        <br/>
        <p>We also provide Services that allow you to send short message service (SMS) messages to your customers (the “Seller-Initiated SMS Services”). You will only use the Seller-Initiated SMS Services in compliance with these Terms and all other applicable laws and regulations of the jurisdiction from which you send messages and in which your messages are received.</p>
        <br />
        <h3><strong>Paid Services</strong></h3>
        <p>Summary: We can collect payments for a paid service by either charging your linked debit/credit card or taking the payment from your transaction proceeds, Gamsa IO-account balance or linked bank account.</p>
        <p>We’ll give you 30 days advance notice if we change our subscription fees, and you can cancel your subscription at any time.</p>
        <br/>
        <p>Gamsa IO’s Services include both paid-for Services (“Paid Services”) and free Services, for which no fees are charged (“Free Services”). Gamsa IO offers certain Paid Services to be paid for on a recurring basis (“Subscription Services”) and others on an as-used basis (“A La Carte Services”). Subscription Services may subject you to recurring fees and/or terms. By signing up for a Subscription Service, including after any free trial period, you agree to pay us the subscription fee and any applicable Taxes (as defined below) (“Subscription Fee”). A La Carte Services may subject you to fees charged per usage and/or terms, including transaction volume. By using an A La Carte Service, you agree to pay the fees and any Taxes incurred at the time of usage (“A La Carte Fees” and, together with Subscription Fees, the “Paid Service Fees”).</p>
        <br/>
        <p>Paid Service Fees may be paid by debit card, credit card, or deducted from your transaction proceeds; however, Paid Service Fees related to hardware may only be paid by debit card or credit card. If you link a debit or credit card to your account, you authorize us to collect Paid Service Fees by debit from your linked debit card or charge to your linked credit card. Regardless of payment device, we reserve the right to collect Paid Service Fees by deduction from your transaction proceeds, the Balance (as defined in the Payment Terms) in your Gamsa IO Account or your linked bank account.</p>
        <br/>
        <p>Your initial subscription billing will commence one day after the kiosk is delivered. Following this date, your subscription and rental (if applicable) payments will be billed on a monthly basis. You may cancel a Subscription Service at any time from your Gamsa IO Account settings. If you cancel a Subscription Service, you will continue to have access to that Subscription Service through the end of your then current billing period, but you will not be entitled to a refund or credit for any Subscription Fee already due or paid. We reserve the right to change our Subscription Fee upon thirty (30) days’ advance notice. Your continued use of Subscription Services after notice of a change to our Subscription Fee will constitute your agreement to such changes.</p>
        <br/>
        <p>Transactions will be facilitated in US Dollars.</p>
        <br />
        <h3><strong>Hardware Sales and Rentals</strong></h3>
        <p>We may sell or rent hardware for use with our kiosk software service. If you rent hardware, you may cancel the rental at any time, but you will be responsible for the return fees and any damages to the hardware. We reserve the right to charge you for any damages to the hardware that exceed normal wear and tear. Each rental will have a written agreement, duly signed and dated by both parties.</p>
        <br />
        <h3><strong>Taxes</strong></h3>
        <p>Summary: Your taxes are your responsibility. But we may have an obligation under applicable law to charge tax on Paid Services and report certain information about you to the IRS.</p>
        <br/>
        <p>Taxes include any and all present or future taxes, charges, fees, levies or other assessments, including, without limitation, income, telecommunications, value-added, goods and services tax or similar taxes, gross receipts, excise, real or personal property, sales, withholding, social security, occupation, use, severance, environmental, license, net worth, payroll, employment, franchise, transfer and recording taxes, fees and charges, imposed by any domestic or foreign taxing authority (“Taxes”).</p>
        <br/>
        <p>Unless otherwise stated, all software services fees are exclusive of any Taxes. You are responsible and liable for determining any and all Taxes required to be assessed, incurred, collected, paid or withheld for your use of the Services. You also are responsible and liable for (a) determining whether Taxes apply to your sale of products and services, payments received, and any other transactions arising from or out of your use of the Services, and (b) calculating, collecting, reporting or remitting any Taxes to the appropriate tax and revenue authorities. Gamsa IO specifically disclaims any liability for such Taxes.</p>
        <br/>
        <p>Notwithstanding the foregoing, Gamsa IO may charge Taxes, as required by law, which you agree to pay, unless you provide Gamsa IO with timely appropriate, complete, and accurate information and documentation satisfying the legal and tax requirements of the relevant governmental or tax authority to establish that the otherwise applicable Tax is not required to be charged by Gamsa IO.</p>
        <br/>
        <p>Gamsa IO may be obligated under applicable laws to report certain information to tax and revenue authorities (“Tax Information”) and/or you with respect to your use of the Services. Upon request, you shall provide Gamsa IO with the necessary information to complete any applicable Tax Information reporting and recertify such information from time to time, as may be required by applicable law. If you use our Services you acknowledge that we will report to the applicable tax and revenue authorities the required Tax Information (including the total amount of payments you received during the relevant reporting period). We also may, but are not obligated to, send to you the Tax Information reported.</p>
        <br/>
        <p>Our kiosk software integrates with Square point of sale, but it is your responsibility to input the correct taxes into Square's system. We are not liable for any errors in tax calculations or any related issues that arise from your use of Square.</p>
        <br />
        <h3><strong>Modification and Termination</strong></h3>
        <p>Summary: We can terminate or deny you access to our services at any time, for any reason. You can also choose to deactivate your account at any time.</p>
        <br/>
        <p>We may terminate these General Terms or any Additional Terms, or suspend or terminate your Gamsa IO Account or your access to any Service, at any time for any reason. We may add or remove, suspend, stop, delete, discontinue or impose conditions on Services or any feature or aspect of a Service. We will take reasonable steps to notify you of termination or these other types of Service changes by email or at the next time you attempt to access your Gamsa IO Account. You may also terminate the General Terms and Additional Terms applicable to your Gamsa IO Account by deactivating your Gamsa IO Account at any time.</p>
        <br />
        <h3><strong>Effect of Termination</strong></h3>
        <p>Summary: Once our relationship ends, we are not responsible for any losses you experience because of the termination of our services or for removing your data from our servers. Some terms of our agreement will still apply even after our relationship ends.</p>
        <br/>
        <p>If these General Terms or your Gamsa IO Account is terminated or suspended for any reason: (a) the license and any other rights granted under these Terms will end, (b) you agree to immediately terminate and cease use of all Services, (c) we may (but have no obligation to) delete your information and account data stored on our servers, and (c) we will not be liable to you or any third party for compensation, reimbursement, or damages for any termination or suspension of the Services, or for deletion of your information or account data. In addition to any payment obligations under the Payment Terms, the following sections of these General Terms survive and remain in effect in accordance with their terms upon termination: Your Content, Copyright and Trademark Infringement, Security, Privacy, Effect of Termination, Ownership, Indemnity, Representations and Warranties, No Warranties, Limitation of Liability and Damages, Third Party Products, Disputes, Binding Individual Arbitration, Governing Law, Limitation on Time to Initiate a Dispute, Assignment, Third Party Service and Links to Other Web Sites, and 29 Other Provisions.</p>
        <br />
        <h3><strong>Your License</strong></h3>
        <p>We grant you a limited, non-exclusive, revocable, non-transferable, non-sublicensable license to use the Paid Services, and a royalty-free, limited, non-exclusive, revocable, non-transferable, non-sublicensable license to use the Free Services as authorized in these General Terms. We may make updates to the Services available to you, which you must accept to continue using the Services. Any such updates may be subject to additional terms made known to you at that time.</p>
        <br />
        <h3><strong>Ownership</strong></h3>
        <p>While we’re happy to let you use our services, you don’t have any ownership rights in them. We can act on any ideas you share with us about how we can improve our products and services free of charge.</p>
        <p>We reserve all rights not expressly granted to you in these General Terms. We own all rights, title, and interest, in and to the Services and all copies of the Services. These General Terms do not grant you any rights to our trademarks or service marks.</p>
        <p>You may submit feedback, comments or ideas about the Services (“Ideas”). Submitting Ideas is entirely voluntary, and we will be free to use such ideas as we see fit without any obligation to you.</p>
        <br />
        <h3><strong>Indemnity</strong></h3>
        <p>If you do something using our services that gets us sued or fined, you agree to cover our losses as described below.</p>
        <p>You will indemnify, defend, and hold us and our processors (and our respective employees, directors, agents, affiliates and representatives) harmless from and against any and all claims, costs, losses, damages, judgments, tax assessments, penalties, interest, and expenses (including without limitation reasonable attorneys’ fees) arising out of or in connection with any claim, action, audit, investigation, inquiry, or other proceeding instituted by any person or entity that arises out of or relates to: (a) any actual or alleged breach of your representations, warranties, or obligations set forth in these Terms; (b) your wrongful or improper use of the Services; (c) your violation of any third-party right, including without limitation any right of privacy, publicity rights or Intellectual Property Rights; (d) your violation of any law, rule or regulation of the United States or any other country; (e) any third-party claims made by your Buyer regarding Gamsa IO’s processing of your customer/Buyer’s Personal Information in connection with providing you with the Services; and (f) any other party’s access and/or use of the Services with your unique name, password or other appropriate security code; and (g) any transaction, purchase, good or service in respect of which Gamsa IO provides, or provided, you with payment processing services in accordance with the Terms.</p>
        <br />
        <h3><strong>Representations and Warranties</strong></h3>
        <p>Summary: Here’s the part where you promise that you are who you say you are, and that you will obey the law and our terms.</p>
        <br/>
        <p>You represent and warrant to us that: (a) you are at least eighteen (18) years of age; (b) you are eligible to register and use the Services and have the right, power, and ability to enter into and perform under these General Terms; (c) any information you provide in connection with the Services, including your business name, accurately and truthfully represents your business or personal identity under which you sell goods and services; (d) you and all transactions initiated by you will comply with all federal, state, and local laws, rules, and regulations applicable to you and/or your business, including the Health Insurance Portability and Accountability Act (“HIPAA”); (e) you will not use the Services, directly or indirectly, for any fraudulent undertaking or in any manner so as to interfere with the operation of the Services; and (f) your use of the Services will be in compliance with these Terms.</p>
        <br />
        <h3><strong>Limited Warranty</strong></h3>
        <p>The purchased Kiosk is covered by a warranty for a period of two years from the date of delivery. See below for warranty details:</p>
        <br/>
        <p>Shipping: The responsibility for shipping costs for warranty hardware to Gamsa LLC lies with you, the Buyer, while Gamsa LLC will cover the shipping costs for returning the kiosk and other hardware parts to the Buyer.</p>
        <br/>
        <p>Parts: All necessary parts for repairs or replacements are included and will be provided at no additional cost.</p>
        <br/>
        <p>This limited warranty does not apply to any kiosks that have been subject to alteration, self-repair or repair by any other entity than Gamsa, tampering, accident, abuse, misuse, fire, acts of god (including, without limitation, earthquake, flood, hurricane, lightning, or tornado) or other external causes. </p>
        <br/>
        <p>The warranty covers defects in hardware materials and workmanship. This warranty is applicable unless a separate written agreement, duly signed and dated by authorized representatives of both parties, is in place. Any such separate agreement shall supersede the default two-year warranty provided herein.</p>
        <br/>
        <p>For any warranty claims or service requests, the Buyer should contact Gamsa LLC directly.</p>
        <br/>
        <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THIS LIMITED WARRANTY IS PROVIDED IN LIEU OF ANY OTHER WARRANTY, WHETHER EXPRESS OR IMPLIED, WRITTEN OR ORAL, WITH RESPECT TO ANY GAMSA OR THIRD PARTY PRODUCT. ACCORDINGLY, GAMSA LLC EXPRESSLY DISCLAIMS ANY WARRANTY OR CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, OR AGAINST HIDDEN OR LATENT DEFECTS. TO THE EXTENT SUCH WARRANTIES CANNOT BE DISCLAIMED, GAMSA LLC LIMITS THE DURATION AND REMEDIES OF SUCH WARRANTIES TO THE DURATION AND REMEDY OF THIS LIMITED WARRANTY. IN NO EVENT WILL GAMSA LLC BE LIABLE FOR ANY LOSS OR DAMAGE OR FOR INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR INDIRECT DAMAGES HOWEVER CAUSED ARISING OUT OF YOUR USE OF OR INABILITY TO USE ANY GAMSA LLC  OR THIRD PARTY PRODUCTS, INCLUDING LOSS OF REVENUE, LOSS OF ACTUAL OR ANTICIPATED PROFITS, LOSS OF GOODWILL, OR LOSS OF REPUTATION.</p>
        <br/>
        <h3><strong>Third Party Products</strong></h3>
        <p>All third party hardware and other products included or sold with the Services are provided solely according to the warranty and other terms specified by the manufacturer, who is solely responsible for service and support for its product. For service, support, or warranty assistance, you should contact the manufacturer directly. GAMSA IO MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO SUCH THIRD PARTY PRODUCTS, AND EXPRESSLY DISCLAIMS ANY WARRANTY OR CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE.</p>
        <br />
        <h3><strong>Disputes</strong></h3>
        <p>When you see the word “Dispute” in these terms, here’s what it means.</p>
        <p>“Disputes” are defined as any claim, controversy, or dispute between you and Gamsa IO, its processors, suppliers or licensors (or their respective affiliates, agents, directors or employees), whether arising before or during the effective period of these Terms, and including any claim, controversy, or dispute based on any conduct of you or Gamsa IO that occurred before the effective date of these Terms, including any claims relating in any way to these Terms or the Services, or any other aspect of our relationship.</p>
        <br />
        <h3><strong>Binding Individual Arbitration</strong></h3>
        <p>Summary: agree to resolve disputes through arbitration instead of going to court, with individual arbitration being the only option (no class or representative actions). The American Arbitration Association will administer the arbitration process. Small claims court can be an alternative for either party, and the party initiating arbitration is responsible for the filing fee. You have the option to reject arbitration within 30 days, but if not, judicial proceedings will occur in specified California courts.</p>
        <br/>
        <p>General: You and Gamsa IO agree that any and all Disputes, except those that are resolved informally or brought in a small claims court, will be arbitrated by a neutral arbitrator who has the power to award the same individual damages and individual relief that a court can. ANY ARBITRATION UNDER THESE GENERAL TERMS WILL ONLY BE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS, CLASS ACTIONS, REPRESENTATIVE ACTIONS, AND CONSOLIDATION WITH OTHER ARBITRATIONS ARE NOT PERMITTED. YOU WAIVE ANY RIGHT TO HAVE YOUR CASE DECIDED BY A JURY AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS ACTION AGAINST GAMSA IO. If any provision of this arbitration agreement is found unenforceable, the unenforceable provision will be severed, and the remaining arbitration terms will be enforced (but in no case will there be a class or representative arbitration).</p>
        <br/>
        <p>Scope of Arbitration: If we are not able to resolve the Dispute by informal negotiation or, as provided below, in a small claims court, all Disputes will be resolved finally and exclusively by binding individual arbitration with a single arbitrator (the “Arbitrator”) administered by the American Arbitration Association (https://www.adr.org) according to this Section and the Commercial Arbitration Rules for that forum, except you and Gamsa IO will have the right to file early or summary dispositive motions and to request that the AAA’s Expedited Procedures apply regardless of the claim amount. Except as set forth above, the Arbitrator shall be responsible for determining all threshold arbitrability issues, including issues relating to whether the General Terms and/or Additional Terms (or any aspect thereof) are enforceable, unconscionable or illusory and any defense to arbitration, including waiver, delay, laches, or estoppel.</p>
        <br/>
        <p>Small Claims Court: Subject to applicable jurisdictional requirements, either party may elect to pursue a Dispute in a local small-claims court rather than through arbitration so long as the matter remains in small claims court and proceeds only on an individual basis. If a party has already submitted an arbitration demand to the AAA, the other party may, in its sole discretion, inform the AAA that it chooses to have the Dispute heard in small claims court. At that time, the AAA will close the arbitration and the Dispute will be heard in the appropriate small claims court, with no fees due from the arbitration respondent.</p>
        <br/>
        <p>Arbitration Fees: In accordance with the AAA Rules, the party initiating the arbitration (either you or us) is responsible for paying the applicable filing fee. For purposes of this arbitration provision, references to you and Gamsa IO also include respective subsidiaries, affiliates, agents, employees, predecessors, successors and assigns as well as authorized users or beneficiaries of the Services.</p>
        <br/>
        <p>Opt Out: You may reject this provision, in which case only a court may be used to resolve any Dispute. To reject this provision, you must send us an opt-out notice (the “Opt Out”) within thirty (30) days after you create a Gamsa IO account or we first provide you with the right to reject this provision.</p>
        <br/>
        <p>Court Proceedings: Subject to and without waiver of the arbitration provisions above, you agree that any judicial proceedings (other than small claims actions as discussed above) will be brought in and you hereby consent to the exclusive jurisdiction and venue in the state courts in the City and County of Torrance, California, or federal court for the Southern District of California.</p>
        <br />
        <h3><strong>Governing Law</strong></h3>
        <p>Our relationship is governed by the laws of California, federal law, or both.</p>
        <p>These General Terms and any Dispute will be governed by the Federal Arbitration Act, as set forth above, and by California law and/or applicable federal law, without regard to its choice of law or conflicts of law principles.</p>
        <br />
        <h3><strong>Limitation on Time to Initiate a Dispute</strong></h3>
        <p>Any action or proceeding by you relating to any Dispute must commence within one year after the cause of action accrues.</p>
        <br />
        <h3><strong>Assignment</strong></h3>
        <p>You may not transfer any rights you have under our terms of service to anyone else.</p>
        <p>Unless expressly authorized by Gamsa IO, these General Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you and any attempted transfer or assignment will be null and void.</p>
        <br />
        <h3><strong>Third Party Services and Links to Other Websites</strong></h3>
        <p>Summary: While you’re using our products and services, you may be offered services from third parties. We are not responsible for any issues you may have with them.</p>
        <br/>
        <p>You may be offered services, products and promotions provided by third parties and not by Gamsa IO, including, but not limited to, third party developers who use Gamsa IO’s services (“Third Party Services”). If you decide to use Third Party Services, you will be responsible for reviewing and understanding the terms and conditions for these services. We are not responsible or liable for the performance of any Third Party Services. Further, you agree to resolve any disagreement between you and a third party regarding the terms and conditions of any Third Party Services with that third party directly in accordance with the terms and conditions of that relationship, and not Gamsa IO. The Services may contain links to third party websites. The inclusion of any website link does not imply an approval, endorsement, or recommendation by Gamsa IO. Such third party websites are not governed by these General Terms. You access any such website at your own risk. We expressly disclaim any liability for these websites. When you use a link to go from the Services to a third party website, our Privacy Notice is no longer in effect. Your browsing and interaction on a third party website, including those that have a link in the Services is subject to that website’s own terms, rules and policies.</p>
        <br />
        <h3><strong>Third-Party Beneficiaries</strong></h3>
        <p>No provision in these General Terms, and any applicable Additional Terms is intended or shall create any rights with respect to the subject matter of these General Terms, and any applicable Additional Terms in any third party.</p>
        <br />
        <h3><strong>Other Provisions</strong></h3>
        <p>These General Terms, and any applicable Additional Terms or Policies, are a complete statement of the agreement between you and Gamsa IO regarding the Services. In the event of a conflict between these General Terms and any other Gamsa IO agreement or Policy, these General Terms will prevail and control the subject matter of such conflict. If any provision of these General Terms or any Additional Term is invalid or unenforceable under applicable law, then it will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect. These General Terms do not limit any rights that we may have under trade secret, copyright, patent, or other laws. No waiver of any term of these General Terms will be deemed a further or continuing waiver of such term or any other term.</p>
        <br />
      </Col>
    </Row>
  )
}

export default TermsOfService
