import React, { useState } from 'react'

import {
  Button,
  Row,
  Col,
  Alert,
  Nav,
  Tab,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'

import {
  Client,
  ToggleSwitch
} from '../shared'
import TaxExemptions from './TaxExemptions'
import { TaxExemptionProvider } from '../state/TaxExemptionProvider'
import '../shared/style.css'

const SquareIntegration = ({
  squareOauthStateToken,
  squareAppID,
  squareBaseURL,
  squareOauthStatus,
  message,
}) => {

  const client = new Client
  let permissions = [
    'CUSTOMERS_WRITE',
    'CUSTOMERS_READ',
    'MERCHANT_PROFILE_READ',
    'ITEMS_READ',
    'ITEMS_WRITE',
    'DEVICE_CREDENTIAL_MANAGEMENT',        // device pairing
    'PAYMENTS_READ',                       // device pairing
    'PAYMENTS_WRITE',                      // device pairing
    'ORDERS_WRITE',                        // device pairing
    'ORDERS_READ',                         // reporting, fulfillment events
    'PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS',// application fee
    'LOYALTY_READ',                        // loyalty program
    'LOYALTY_WRITE',                       // loyalty program
    'DISCOUNT_CODES_READ',                 // discount code search
    'DISCOUNT_CODES_WRITE'                 // discount code redeem
  ].join('+')

  const oauthUrl = `${squareBaseURL}/oauth2/authorize` +
    `?client_id=${squareAppID}` +
    `&scope=${permissions}` +
    "&session=false" +
    `&state=${squareOauthStateToken}`

  const integrated = squareOauthStatus === 'valid'
  const [isIntegrated, setIntegrated] = useState(integrated)

  const onSwitchAction = () => {
    squareOauthStatus !== 'valid' ?
      window.location.href = oauthUrl :
      client.revokeBusinessSecret('squareOauth')
        .then(() => window.location.href = '/')
  }

  const style = {
    card: { margin: 50 },
    update: {
      backgroundColor: "#D3D3D3",
      border: "none",
      color: "black",
      fontSize: 18
    }
  }


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Updates OAuth to latest required permissions
    </Tooltip>
  )
  return (
    <div class="card border-light mb-3" style={style.card}>
      <h5 class="card-header"><strong>Account</strong></h5>
      <div class="card-body">
        {
          message ? (
            <Alert variant='warning'>
              {message}
            </Alert>
          ) : null
        }

        <ToggleSwitch
          checked={isIntegrated}
          onChange={onSwitchAction}
          message="Sync with Square (OAuth)"
          useFriction
          frictionMessage='This will disable all of your kiosks. Are you sure?'
        />

        <br />
        <br />

        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <Button
            style={style.update}
            onClick={() => window.location.href = oauthUrl}
          >
            Update to Latest Permissions
          </Button>
        </OverlayTrigger>

        <br />
        <br />
      </div>
    </div>
  )
}

const SubscriptionButton = ({
  message
}) => {
  const client = new Client

  const style = {
    card: { margin: '50px' },
    stripe: { fontSize: 18 },
    button: { fontSize: 18, fontWeight: 'bold' }
  }

  return (
    <div class="card border-light mb-3" style={style.card}>
      <h5 class="card-header"><strong>Payment Management</strong></h5>
      <div class="card-body">
        {
          message ? (
            <Alert>{message}</Alert>
          ) : null
        }
        <Row>
          <Col>
            <p style={style.stripe}>Manage your Stripe subscription billing (if you have one)</p>
          </Col>
          <Col>
            <Button
              style={style.button}
              href="https://billing.stripe.com/p/login/fZe5n72j72N78807ss"
            >
              Stripe
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const SettingsMain = ({
  squareOauthStateToken,
  squareAppID,
  squareBaseURL,
  squareOauthStatus,
  taxExemptions,
  message,
  currentCategory,
}) => {

  const style = {
    nav: { fontSize: 20, marginTop: 50, marginLeft: 50 },
    tab: { margin: 50, marginBottom: 100 },
    tabContainer: { margin: 50 }
  }

  const client = new Client

  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={currentCategory ? "third" : "first"}
        style={style.tabContainer}
      >
        <Row>
          <Col sm={2}>
            <Nav style={style.nav} variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Account</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Billing</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Tax Exemptions</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content style={style.tab} >
              <Tab.Pane eventKey="first">
                <SquareIntegration
                  squareOauthStateToken={squareOauthStateToken}
                  squareAppID={squareAppID}
                  squareBaseURL={squareBaseURL}
                  squareOauthStatus={squareOauthStatus}
                  message={message}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <SubscriptionButton />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <TaxExemptionProvider>
                  <TaxExemptions
                    taxExemptions={taxExemptions}
                    currentCategory={currentCategory}
                  />
                </TaxExemptionProvider>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  )
}

export default SettingsMain
